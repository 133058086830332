import React from 'react'
import CabsHeroSection from '../components/CabsHeroSection'
import CabsCards from '../components/CabsCards'
import CabsAbout from './CabsAbout'

const Cabs = () => {
    return (
        <main>
            <CabsHeroSection />
            <CabsCards />
            <CabsAbout />
        </main>)
}

export default Cabs