import React from 'react';

const Singletourfeature = ({ product }) => {
    return (
        <section className="pb-5">
            <div className="container">
                <ul className="list-unstyled row g-3 mb-0">
                    <li className="col-6 col-xl-3">
                        <div className="mini-card card-simple card-mobile-small rounded">
                            <span className="card-icon">
                                <i className="hicon hicon-promo-code"></i>
                            </span>
                            <div className="card-content">
                                <small className="card-desc">Tour code</small>
                                <h2 className="card-title">{product.tourCode}</h2>
                            </div>
                        </div>
                    </li>
                    <li className="col-6 col-xl-3">
                        <div className="mini-card card-simple card-mobile-small rounded">
                            <span className="card-icon">
                                <i className="hicon hicon-poi-text-search"></i>
                            </span>
                            <div className="card-content">
                                <small className="card-desc">Start</small>
                                <h2 className="card-title">{product.startLocation}</h2>
                            </div>
                        </div>
                    </li>
                    <li className="col-6 col-xl-3">
                        <div className="mini-card card-simple card-mobile-small rounded">
                            <span className="card-icon">
                                <i className="hicon hicon-poi"></i>
                            </span>
                            <div className="card-content">
                                <small className="card-desc">End</small>
                                <h2 className="card-title">{product.endLocation}</h2>
                            </div>
                        </div>
                    </li>
                    <li className="col-6 col-xl-3">
                        <div className="mini-card card-simple card-mobile-small rounded">
                            <span className="card-icon">
                                <i className="hicon hicon-travelers"></i>
                            </span>
                            <div className="card-content">
                                <small className="card-desc">Type tour</small>
                                <h2 className="card-title">{product.typeTour}</h2>
                            </div>
                        </div>
                    </li>
                    <li className="col-6 col-xl-3">
                        <div className="mini-card card-simple card-mobile-small rounded">
                            <span className="card-icon">
                                <i className="hicon hicon-time-clock"></i>
                            </span>
                            <div className="card-content">
                                <small className="card-desc">Age range</small>
                                <h2 className="card-title">{product.ageRange}</h2>
                            </div>
                        </div>
                    </li>
                    <li className="col-6 col-xl-3">
                        <div className="mini-card card-simple card-mobile-small rounded">
                            <span className="card-icon">
                                <i className="hicon hicon-seriously-multilingual"></i>
                            </span>
                            <div className="card-content">
                                <small className="card-desc">Language</small>
                                <h2 className="card-title">{product.language}</h2>
                            </div>
                        </div>
                    </li>
                    <li className="col-6 col-xl-3">
                        <div className="mini-card card-simple card-mobile-small rounded">
                            <span className="card-icon">
                                <i className="hicon hicon-installment-payment"></i>
                            </span>
                            <div className="card-content">
                                <small className="card-desc">Payments</small>
                                <h2 className="card-title">{product.payments ? 'Pay later' : 'Immediate'}</h2>
                            </div>
                        </div>
                    </li>
                    <li className="col-6 col-xl-3">
                        <div className="mini-card card-simple card-mobile-small rounded">
                            <span className="card-icon">
                                <i className="hicon hicon-cancel-booking"></i>
                            </span>
                            <div className="card-content">
                                <small className="card-desc">Cancel tour</small>
                                <h2 className="card-title">{product.isCancel ? 'No' : 'Yes/Free'}</h2>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    );

};

export default Singletourfeature;
