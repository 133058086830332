import React, { useEffect } from 'react';
import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide.min.css';

const AboutUs = () => {
    useEffect(() => {
        new Splide('.testimonials-slider-1', {
            type: 'loop',
            perPage: 3,
            arrows: true,
            pagination: false,
            autoplay: true,
            breakpoints: {
                1024: {
                    perPage: 2,
                },
                768: {
                    perPage: 1,
                },
            },
        }).mount();
    }, []);

    return (
        <section className="p-top-90 p-bottom-90 bg-gray-gradient">
            <div className="container">
                <div id="testimonial-slider" className="testimonials-slider-1 splide mb-5">
                    <div className="d-xl-flex align-items-xl-center mb-3">
                        <div className="block-title me-auto">
                            <small className="sub-title">Genuine Reviews</small>
                            <h2 className="h1 title">Tourists talk about us</h2>
                        </div>
                        <div className="d-lg-flex align-items-lg mt-3">
                            <div className="d-md-flex align-items-md-center me-md-4">
                                <div className="extra-info me-4">
                                    <strong>+95K</strong>
                                    <span>Tour bookings</span>
                                </div>
                                <div className="extra-info me-4">
                                    <strong>4.9</strong>
                                    <span className="fw-medium text-secondary">
                                        <span className="star-rate-view star-rate-size-sm">
                                            <span className="star-value rate-50"></span>
                                        </span>
                                        <span>(+85K reviews)</span>
                                    </span>
                                </div>
                            </div>
                            <div className="splide__arrows splide__arrows__right">
                                <button className="splide__arrow splide__arrow--prev me-2" style={{ backgroundColor: '#5143d9', height: '40px', width: '40px', opacity: 1, borderRadius: '5px' }}>
                                    <i className="hicon hicon-edge-arrow-left"></i>
                                </button>
                                <button className="splide__arrow splide__arrow--next" style={{ backgroundColor: '#5143d9', height: '40px', width: '40px', opacity: 1, borderRadius: '5px' }}>
                                    <i className="hicon hicon-edge-arrow-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="splide__track pt-2 pb-2">
                        <ul className="splide__list">
                            {testimonialsData.map((testimonial, index) => (
                                <li className="splide__slide p-1" key={index}>
                                    <div className="testimonial-box shadow-sm rounded mb-1 hover-effect">
                                        <span className="testimonial-icon"><i className="hicon hicon-message-right"></i></span>
                                        <div className="testimonial-client">
                                            <img src={testimonial.image} srcSet={testimonial.image2x} alt="" />
                                            <div className="name">
                                                <h3 className="h5 mb-0">{testimonial.name}</h3>
                                                <span>{testimonial.location}</span>
                                            </div>
                                        </div>
                                        <div className="testimonial-content">
                                            <blockquote className="testimonial-review">{testimonial.review}</blockquote>
                                            <div className="testimonial-star">
                                                <span className="star-rate-view star-rate-size-sm">
                                                    <span className="star-value rate-45"></span>
                                                </span>
                                                <span className="testimonial-date rounded-1">{testimonial.date}</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

// Testimonial data array
const testimonialsData = [
    {
        image: './assets/img/avatars/a1.jpg',
        image2x: './assets/img/avatars/a1@2x.jpg',
        name: 'John Doe',
        location: 'Venice, Italy',
        review: 'Our trip to Tripnow was amazing! Tripnow Travel Agency organized everything perfectly, from the hotels to the sightseeing spots. I was very impressed and will definitely return!',
        date: 'Jun 25 24',
    },
    {
        image: './assets/img/avatars/a2.jpg',
        image2x: './assets/img/avatars/a2@2x.jpg',
        name: 'Emily Smith',
        location: 'Chicago, USA',
        review: 'We had an unforgettable vacation in Tripnow thanks to the excellent service of Tripnow Travel Agency. The itinerary was well-arranged, and the support team was very helpful. Best trip ever!',
        date: 'Jun 28 24',
    },
    {
        image: './assets/img/avatars/a3.jpg',
        image2x: './assets/img/avatars/a3@2x.jpg',
        name: 'Carlos Martinez',
        location: 'Madrid, Spain',
        review: 'Tripnow exceeded my expectations! The destinations were beautiful, and everything was well-organized. Highly recommend for anyone looking to explore the world.',
        date: 'Jul 02 24',
    },
    {
        image: './assets/img/avatars/a4.jpg',
        image2x: './assets/img/avatars/a4@2x.jpg',
        name: 'Sophia Wang',
        location: 'Beijing, China',
        review: 'Thank you, Tripnow, for an amazing experience! The staff was so friendly, and we felt well taken care of throughout our journey. A trip to remember!',
        date: 'Jul 10 24',
    },
    {
        image: './assets/img/avatars/a5.jpg',
        image2x: './assets/img/avatars/a5@2x.jpg',
        name: 'James Lee',
        location: 'Toronto, Canada',
        review: 'The best travel experience I\'ve ever had. Tripnow\'s team planned every detail perfectly, making my vacation stress-free and enjoyable. Looking forward to my next trip with them!',
        date: 'Jul 15 24',
    },
    {
        image: './assets/img/avatars/a6.jpg',
        image2x: './assets/img/avatars/a6@2x.jpg',
        name: 'Amelia Brown',
        location: 'Sydney, Australia',
        review: 'From start to finish, Tripnow made our trip easy and enjoyable. The tours, accommodations, and customer service were top-notch. Highly recommend their services!',
        date: 'Jul 20 24',
    },
    {
        image: './assets/img/avatars/a7.jpg',
        image2x: './assets/img/avatars/a7@2x.jpg',
        name: 'Oliver Davis',
        location: 'New York, USA',
        review: 'Tripnow turned our dream vacation into a reality! The trip was smooth and filled with wonderful surprises. Can’t wait for the next adventure!',
        date: 'Jul 25 24',
    },
    // Add additional testimonials as needed
];

export default AboutUs;
