import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const AdminResistration = () => {
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [repassword, setRepassword] = useState("");
    const [email, setEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== repassword) {
            setErrorMessage("Passwords do not match!");
            return;
        }
        try {
            await axios.post('/admin/api/register', {
                fullname: name,  // Ensure you're using "fullname" as per your backend code
                email,
                password
            });
            setName("");
            setEmail("");
            setPassword("");
            setRepassword("");
            setErrorMessage("");  // Clear any previous error messages
            // Redirect to another page (e.g., login page) or show a success message
            window.location.href = "/admin";  // or any other route you want to redirect to

        } catch (error) {
            if (error.response && error.response.status === 409) {
                setErrorMessage("User already exists");
            } else {
                setErrorMessage("There was an error registering the user!");
            }
            console.error("There was an error registering the user!", error);
        }
    }

    return (
        <main>
            <section className="p-top-90 p-bottom-90 bg-light-gray-gradient" data-aos="fade">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-xl-5 col-lg-7 col-md-9">
                            <div className="card border-0 shadow-sm p-xl-2" data-aos="fade">
                                <div className="card-body">
                                    <div className="border-bottom mb-4">
                                        <h1 className="h2 text-body-emphasis">Register</h1>
                                    </div>
                                    {errorMessage && (
                                        <div className="alert alert-danger">
                                            {errorMessage}
                                        </div>
                                    )}
                                    <form onSubmit={handleSubmit}>
                                        <div className="border-bottom pb-4">
                                            <div className="mb-4">
                                                <label htmlFor="txtFullname" className="form-label">Full Name<span className="text-danger">*</span></label>
                                                <input
                                                    className="form-control shadow-sm"
                                                    id="txtFullname"
                                                    name="name"
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div className="mb-4">
                                                <label htmlFor="txtEmail" className="form-label">Email<span className="text-danger">*</span></label>
                                                <input
                                                    className="form-control shadow-sm"
                                                    id="txtEmail"
                                                    name="email"
                                                    type="email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div className="mb-4">
                                                <label htmlFor="txtPassword" className="form-label">Password<span className="text-danger">*</span></label>
                                                <input
                                                    className="form-control shadow-sm"
                                                    id="txtPassword"
                                                    name="password"
                                                    type="password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div className="mb-4">
                                                <label htmlFor="txtRetypePassword" className="form-label">Retype password<span className="text-danger">*</span></label>
                                                <input
                                                    className="form-control shadow-sm"
                                                    id="txtRetypePassword"
                                                    name="repassword"
                                                    type="password"
                                                    value={repassword}
                                                    onChange={(e) => setRepassword(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div className="form-check mb-4">
                                                <input className="form-check-input shadow-sm" type="checkbox" id="chckAgree" required />
                                                <label className="form-check-label" htmlFor="chckAgree"> I agree to <Link to="#" className="hover">terms and policy<span className="text-danger">*</span></Link>. </label>
                                            </div>
                                            <button className="btn btn-primary w-100" type="submit" id="btnSubmit">
                                                <i className="hicon hicon-profiles"></i>
                                                <span>Register</span>
                                            </button>
                                        </div>
                                        <div className="mt-4">
                                            <span>Already have an account? <Link to="/admin">Login</Link></span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default AdminResistration;
