import React, { useMemo, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import UserProfileCard from '../components/UserProfileCard';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProducts } from '../redux/productSlice';

const Booking = ({ loggedIn, adminLoggedIn, setLoggedIn, setAdminLoggedIn }) => {
    const userData = useSelector((state) => state.user.userData);
    const dispatch = useDispatch();
    const { products, loading } = useSelector((state) => state.product);
    const dataLoadedRef = useRef(false);

    // State for the selected booking status
    const [selectedStatus, setSelectedStatus] = useState("All status");

    useEffect(() => {
        dispatch(fetchProducts());
    }, [dispatch]);

    useEffect(() => {
        if (!loading && products.length > 0 && !dataLoadedRef.current) {
            dataLoadedRef.current = true;
        }
    }, [loading, products]);

    const userProducts = useMemo(() => {
        if (!userData || !userData.orders || !products) return [];
        const orderProductIds = userData.orders.map(order => order.product);
        return products.filter(product => orderProductIds.includes(product._id));
    }, [userData, products]);

    const handleStatusChange = (event) => {
        setSelectedStatus(event.target.value);
    };

    return (
        <main>
            <div className="p-top-90 p-bottom-90 bg-gray-gradient" data-aos="fade">
                {/* Title */}
                <section className="container">
                    <div className="d-lg-flex align-items-lg-end pb-4">
                        <div className="block-title me-auto">
                            <span className="sub-title">My Account</span>
                            <h1 className="display-5 title">Booking</h1>
                        </div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mt-3">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Booking</li>
                            </ol>
                        </nav>
                    </div>
                </section>
                {/* /Title */}

                {/* Booking */}
                <section className="container">
                    <div className="row g-0 g-xl-8">
                        <div className="col-12 col-xl-9">
                            {/* Booking list */}
                            <div className="pe-xl-5">
                                <div className="card border-0 shadow-sm">
                                    <div className="card-body">
                                        <div className="d-flex align-items-center justify-content-between border-bottom mb-4">
                                            <h2 className="h3 ff-primary mb-4 text-body-emphasis me-auto">{userData.orders.length} Booking</h2>
                                            <div className="mb-4">
                                                <select
                                                    className="form-select shadow-sm dropdown-select mnw-150"
                                                    aria-label="Booking status"
                                                    value={selectedStatus}
                                                    onChange={handleStatusChange}
                                                >
                                                    <option value="All status">All status</option>
                                                    <option value="1">Completed</option>
                                                    <option value="2">Unpaid</option>
                                                    <option value="3">Cancelled</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead className="text-center">
                                                    <tr className="table-light">
                                                        <th scope="col"><strong>ID</strong></th>
                                                        <th scope="col"><strong>Tour</strong></th>
                                                        <th scope="col"><strong>Amount</strong></th>
                                                        <th scope="col"><strong>Date</strong></th>
                                                        <th scope="col"><strong>Status</strong></th>
                                                        <th scope="col"><strong>Details</strong></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {userProducts.map((product, i) => {
                                                        const productID = product._id;
                                                        const orderData = userData.orders.filter(order => order.product === productID);
                                                        const date = new Date(orderData[0].departureDate);
                                                        const options = { year: 'numeric', month: 'short', day: '2-digit' };
                                                        const formattedDate = date.toLocaleDateString('en-US', options);
                                                        return (
                                                            <tr key={i}>
                                                                <td className="text-center"><span>{i + 1}</span></td>
                                                                <td className="text-nowrap">
                                                                    <div className="d-flex flex-column">
                                                                        <Link to="/booking-details" className="link-dark link-hover"><strong>{product.name}</strong></Link>
                                                                        <small className="text-nowrap d-block">code:{product.tourCode}</small>
                                                                    </div>
                                                                </td>
                                                                <td className="text-center"><strong><sup>$</sup>{product.price}</strong></td>
                                                                <td className="text-center"><small className="text-nowrap">{formattedDate}</small></td>
                                                                <td className="text-center"><span className="booking-status booking-unpaid">Unpaid</span></td>
                                                                <td className="text-center">
                                                                    <Link to="/booking-details" className="circle-icon circle-icon-link circle-icon-sm" title="Detail booking">
                                                                        <i className="hicon hicon-flights-one-ways"></i>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="text-center">
                                            <Link to="#" className="fw-medium">
                                                <i className="hicon hicon-bold hicon-refresh"></i>
                                                <span>Load More</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Booking list */}
                        </div>
                        <div className="col-12 col-xl-3">
                            {/* Account menu */}
                            <div className="card border-0 shadow-sm sticky-top sticky-top-120 d-none d-xl-block z-0">
                                <UserProfileCard adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} setAdminLoggedIn={setAdminLoggedIn} setLoggedIn={setLoggedIn} />
                            </div>
                            {/* Account menu */}
                        </div>
                    </div>
                </section>
                {/* /Booking */}
            </div>
        </main>
    );
}

export default Booking;
