import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isAdminLoggedIn: localStorage.getItem('isAdminLoggedIn') === 'true',
    adminData: JSON.parse(localStorage.getItem('adminData')) || {},
};

const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        setUserData: (state, action) => {
            state.adminData = action.payload;
            state.isAdminLoggedIn = action.payload.isLoggedIn; // Assuming `isLoggedIn` is coming from backend data
            localStorage.setItem('isAdminLoggedIn', 'true');
            localStorage.setItem('adminData', JSON.stringify(action.payload));
        },

        adminlogout: (state) => {
            state.isAdminLoggedIn = false;
            state.adminData = {};
            localStorage.removeItem('isAdminLoggedIn');
            localStorage.removeItem('adminData');
        },
    },
});

export const { setUserData, adminlogout } = adminSlice.actions;
export default adminSlice.reducer;
