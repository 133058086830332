import React from 'react';

const Destinationsabout = ({ destination }) => {
    // console.log(destination);

    return (
        <section className="p-top-90 p-bottom-90 bg-gray-gradient">
            <div className="container">
                <div className="row g-0">
                    <div className="col-12 col-xl-6 order-1 order-xl-0">
                        {/* Image */}
                        <div className="pe-xl-5">
                            <div className="image-info image-info-right image-info-vertical mb-5">
                                <div className="vertical-title">
                                    <small>
                                        <strong className="text-primary fw-semibold">{destination}</strong> - Attractive <strong className="text-body">destinations</strong>
                                    </small>
                                </div>
                                <div className="image-center rounded">
                                    <img
                                        src="/assets/img/about/a4.jpg"
                                        srcSet="/assets/img/about/a4@2x.jpg 2x"
                                        className="w-100"
                                        alt="Moliva"
                                    />
                                </div>
                                <div className="info-top-right">
                                    <div className="vertical-review rounded">
                                        <div className="review-content">
                                            <h3 className="h1 review-score">4.9</h3>
                                            <span className="star-rate-view star-rate-size-sm">
                                                <span className="star-value rate-50"></span>
                                            </span>
                                            <small className="review-total">
                                                <strong>2394</strong> reviews
                                            </small>
                                            <small className="review-label">Best choice</small>
                                        </div>
                                        <div className="review-footer">
                                            <small>Tripadvisor</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* /Image */}
                    </div>
                    <div className="col-12 col-xl-6 order-0 order-xl-1">
                        {/* Content */}
                        <div className="pt-4 mb-5 mb-xl-0">
                            <div className="block-title">
                                <small className="sub-title">Beautiful & Romantic</small>
                                <h2 className="h1 title">About {destination}</h2>
                            </div>
                            <p>
                                {destination} is a breathtaking country known for its stunning natural landscapes. From lush green valleys and majestic mountains to pristine beaches and serene lakes, {destination} offers a diverse array of picturesque views that captivate every traveler. The country’s unspoiled beauty provides the perfect backdrop for outdoor adventures and peaceful retreats alike.
                            </p>
                            <p>
                                Beyond its scenic wonders, {destination} is home to incredibly friendly people and a rich cultural heritage. The vibrant culture is a tapestry of unique traditions, colorful festivals, and diverse culinary delights that reflect the country's deep historical roots and artistic expressions. In Moliva, every visit is an opportunity to experience warmth, hospitality, and a true sense of cultural richness.
                            </p>
                        </div>
                        {/* /Content */}
                    </div>
                </div>
                {/* Overview */}
                <div className="row g-3 g-lg-4 mb-0">
                    <div className="col-6 col-xl-3">
                        <div className="mini-card card-simple card-mobile-small">
                            <span className="card-icon">
                                <i className="hicon hicon-global"></i>
                            </span>
                            <div className="card-content">
                                <small className="card-desc">Continent</small>
                                <h3 className="h5 card-title">Asia</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-xl-3">
                        <div className="mini-card card-simple card-mobile-small">
                            <span className="card-icon">
                                <i className="hicon hicon-poi"></i>
                            </span>
                            <div className="card-content">
                                <small className="card-desc">State</small>
                                <h3 className="h5 card-title">Uttar Pradesh</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-xl-3">
                        <div className="mini-card card-simple card-mobile-small">
                            <span className="card-icon">
                                <i className="hicon hicon-seriously-multilingual"></i>
                            </span>
                            <div className="card-content">
                                <small className="card-desc">Language</small>
                                <h3 className="h5 card-title">English, Hindi</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-xl-3">
                        <div className="mini-card card-simple card-mobile-small">
                            <span className="card-icon">
                                <i className="hicon hicon-more-money"></i>
                            </span>
                            <div className="card-content">
                                <small className="card-desc">Currency</small>
                                <h3 className="h5 card-title">INR</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-xl-3">
                        <div className="mini-card card-simple card-mobile-small">
                            <span className="card-icon">
                                <i className="hicon hicon-light-snow"></i>
                            </span>
                            <div className="card-content">
                                <small className="card-desc">Weather</small>
                                <h3 className="h5 card-title">4 Seasons</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-xl-3">
                        <div className="mini-card card-simple card-mobile-small">
                            <span className="card-icon">
                                <i className="hicon hicon-wintry-mix-snow-sleet"></i>
                            </span>
                            <div className="card-content">
                                <small className="card-desc">Winter (avg)</small>
                                <h3 className="h5 card-title">41°F/5°C</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-xl-3">
                        <div className="mini-card card-simple card-mobile-small">
                            <span className="card-icon">
                                <i className="hicon hicon-partly-cloudy-day"></i>
                            </span>
                            <div className="card-content">
                                <small className="card-desc">Summer (avg)</small>
                                <h3 className="h5 card-title">68°F/20°C</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-xl-3">
                        <div className="mini-card card-simple card-mobile-small">
                            <span className="card-icon">
                                <i className="hicon hicon-time-clock"></i>
                            </span>
                            <div className="card-content">
                                <small className="card-desc">Time Zone</small>
                                <h3 className="h5 card-title">GMT+2</h3>
                            </div>
                        </div>
                    </div>
                </div>
                {/* /Overview */}
            </div>
        </section>
    );
};

export default Destinationsabout;
