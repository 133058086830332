import React from 'react'
import { Link } from 'react-router-dom'


const TermandConditon = () => {
    return (
        <section className="p-top-90 p-bottom-90 bg-gray-gradient">

            <div className="container">
                <div className="mb-4">
                    <div className="row g-0 g-md-2 align-items-center">
                        <div className="col-12 col-xl-8 ">
                            {/* <!-- Title --> */}
                            <div className="block-title me-auto">
                                <h2 className="h1 title">Privacy Policy</h2>
                                <small className="sub-title">Last updated on 07-11-2024</small>
                            </div>
                            <div className="col-12">
                                <h3 className="h3 title">Introduction</h3>
                                <p > Welcome to <Link to="/"><span style={{ color: "blue" }}>Tripow </span> </Link>  These Terms and Conditions ("Terms") govern your use of our website <Link to="/"><span style={{ color: "blue" }} >(tripnow.cc)</span></Link> and our services. By accessing or using our website and services, you agree to comply with and be bound by these Terms.</p>
                            </div>
                            <div className="col-12">
                                <h3 className="h3 title">Use of Services</h3>
                                <p ><span style={{ fontWeight: "500", opacity: "0.9" }}>1. Eligibility:  </span> You must be at least 18 years old to use our services.</p>
                                <p ><span style={{ fontWeight: "500", opacity: "0.9" }}>2. Account Responsibilities: </span> You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</p>
                                <p ><span style={{ fontWeight: "500", opacity: "0.9" }}>3. Prohibited Uses: </span> You agree not to use our services for any unlawful or prohibited activities, including but not limited to:</p>
                                <ul>
                                    <li>Engaging in any activity that violates any applicable law or regulation.
                                    </li>
                                    <li>Attempting to gain unauthorized access to our systems or networks.
                                    </li>
                                    <li>Interfering with the security or integrity of our website or services.
                                    </li>
                                    <li>Transmitting any viruses, malware, or other harmful code.
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12">
                                <h3 className="h3 title">Intellectual Property
                                </h3>
                                <p ><span style={{ fontWeight: "500", opacity: "0.9" }}>Ownership: </span> All content on our website, including text, graphics, logos, and software, is the property of QuantumWebs or its licensors and is protected by intellectual property laws.
                                </p>
                                <p ><span style={{ fontWeight: "500", opacity: "0.9" }}>License: </span> You are granted a limited, non-exclusive, non-transferable license to access and use our website for personal, non-commercial purposes. You may not reproduce, distribute, modify, or create derivative works from our content without our prior written consent.</p>
                            </div>
                            <div className="col-12">
                                <h3 className="h3 title">Privacy Policy</h3>
                                <p >Our <Link to="/privacypolicy"> <span style={{ color: "blue" }}>Privacy Policy</span></Link> explains how we collect, use, and protect your personal information. By using our website and services, you agree to the terms of our <Link to="/privacypolicy">  <span style={{ color: "blue" }}>Privacy Policy</span></Link>.</p>
                            </div>
                            <div className="col-12">
                                <h3 className="h3 title">Disclaimer</h3>
                                <p >Our services are provided "as is" and "as available" without any warranties, express or implied. QuantumWebs does not warrant that our services will be uninterrupted, error-free, or secure. We disclaim all warranties, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>
                            </div>
                            <div className="col-12">
                                <h3 className="h3 title">Limitation of Liability</h3>
                                <p >To the fullest extent permitted by law, QuantumWebs shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of our services, including but not limited to damages for loss of profits, data, or other intangible losses.</p>
                            </div>
                            <div className="col-12">
                                <h3 className="h3 title">Indemnification</h3>
                                <p >You agree to indemnify and hold QuantumWebs harmless from any claims, damages, losses, liabilities, and expenses arising out of or in connection with your use of our services or your violation of these Terms.</p>
                            </div>
                            <div className="col-12">
                                <h3 className="h3 title">Changes to Terms</h3>
                                <p >QuantumWebs reserves the right to modify or replace these Terms at any time. If we make material changes to these Terms, we will notify you by posting a notice on our website or by sending you an email. Your continued use of our website and services after the effective date of the revised Terms constitutes your acceptance of the revised Terms.</p>
                            </div>
                            <div className="col-12">
                                <h3 className="h3 title">Governing Law</h3>
                                <p >These Terms are governed by the laws of India. Any dispute arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts in Varanasi, Uttar Pradesh.</p>
                            </div>
                            <div className="col-12">
                                <h3 className="h3 title">Contact Us</h3>
                                <p >If you have any questions about these Terms, please contact us at:</p>
                                <p style={{ fontWeight: "bold" }}>TripNow</p>
                                <div className="contact-info">
                                    <p>
                                        <span>1063 Maruti Nagar Colony, Varanasi, India - 221011</span>
                                    </p>
                                    <p>
                                        <span>+91 9236975458</span>
                                    </p>
                                    <p>
                                        <Link to="mailto:Booking@example.com">tripnow.cc@gmail.com</Link>
                                    </p>

                                </div>
                            </div>
                            {/* <!-- /Title --> */}
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default TermandConditon