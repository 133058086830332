import React from 'react'
import { useSelector } from 'react-redux'; // Import useSelector


const AdminPanel = () => {
    const userData = useSelector((state) => state.admin.adminData);


    return (
        <div>Welcome {userData.fullname}
        </div>
    )
}

export default AdminPanel