import React, { useEffect, useRef } from 'react';
import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide.min.css';

const AboutWhyChooseUs = () => {
    const sliderRef = useRef(null);

    useEffect(() => {
        const splide = new Splide(sliderRef.current, {
            perPage: 4,
            gap: '1.5rem',
            padding: 0,
            speed: 1000,
            type: 'loop',
            focus: 0,
            arrows: true, // Enable arrows
            pagination: false,
            breakpoints: {
                1200: {
                    perPage: 1,
                    padding: { left: 0, right: '280px' },
                },
                992: {
                    perPage: 1,
                    padding: { left: 0, right: '250px' },
                },
                576: {
                    perPage: 1,
                    gap: '0.75rem',
                    padding: 0,
                },
            },
        });

        splide.on('mounted', () => {
            sliderRef.current.style.visibility = 'visible'; // Set visibility to visible on mount
        });

        splide.mount();

        // Cleanup on unmount
        return () => {
            splide.destroy();
        };
    }, []);

    return (
        <section className="hero">
            <div className="hero-bg">
                <img src="./assets/img/background/b1.jpg" srcSet="./assets/img/background/b6@2x.jpg 2x" alt="" />
            </div>
            <div className="bg-content container">
                <div className="p-top-90 p-bottom-50">
                    <div className="block-title text-center pb-4">
                        <small className="sub-title text-white">Great experience</small>
                        <h2 className="h1 title text-white">Why choose us</h2>
                    </div>
                    <div ref={sliderRef} id="testimonial-slider" className="why-slider splide w-100 mb-5 mb-xl-0" style={{ visibility: 'hidden' }}>
                        <div className="splide__track pt-2 pb-2">
                            <ul className="splide__list">
                                <li className="splide__slide">
                                    <div className="transparent-card rounded hover-effect">
                                        <div className="card-icon">
                                            <i className="hicon hicon-family-with-teens"></i>
                                        </div>
                                        <h3 className="h5 card-title">Leading travel agency in Moliva</h3>
                                        <p className="card-desc">
                                            Top-rated agency in Moliva is renowned for exceptional service and unforgettable travel experiences.
                                        </p>
                                    </div>
                                </li>
                                <li className="splide__slide">
                                    <div className="transparent-card rounded hover-effect">
                                        <div className="card-icon">
                                            <i className="hicon hicon-regular-travel-protection"></i>
                                        </div>
                                        <h3 className="h5 card-title">Years of experience in tour operations</h3>
                                        <p className="card-desc">
                                            With years of expertise, we excel at organizing seamless, enjoyable, and memorable tours for every traveler.
                                        </p>
                                    </div>
                                </li>
                                <li className="splide__slide">
                                    <div className="transparent-card rounded hover-effect">
                                        <div className="card-icon">
                                            <i className="hicon hicon-tours"></i>
                                        </div>
                                        <h3 className="h5 card-title">Flexible tour packages and bookings</h3>
                                        <p className="card-desc">
                                            We offer customizable trips with flexible packages and convenient booking options tailored to your needs.
                                        </p>
                                    </div>
                                </li>
                                <li className="splide__slide">
                                    <div className="transparent-card rounded hover-effect">
                                        <div className="card-icon">
                                            <i className="hicon hicon-tours"></i>
                                        </div>
                                        <h3 className="h5 card-title">Best prices with attractive Offers</h3>
                                        <p className="card-desc">
                                            Enjoy unbeatable prices and exclusive offers, ensuring you receive great value with a memorable vacation.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        {/* Custom Arrow Buttons */}
                        <div className="splide__arrows splide__arrows__right" style={{ position: "absolute", top: "-20px" }}>
                            <button className="splide__arrow splide__arrow--prev me-2" style={{ backgroundColor: '#5143d9', height: '40px', width: '40px', opacity: 1, borderRadius: '5px' }}>
                                <i className="hicon hicon-edge-arrow-left"></i>
                            </button>
                            <button className="splide__arrow splide__arrow--next" style={{ backgroundColor: '#5143d9', height: '40px', width: '40px', opacity: 1, borderRadius: '5px' }}>
                                <i className="hicon hicon-edge-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutWhyChooseUs;
