import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk for fetching hotel data
export const fetchHotels = createAsyncThunk('hotel/fetchHotels', async () => {
    console.log("fetchHotels action called...");
    const response = await axios.get('/api/hotelData');

    return response.data;  // Assuming the backend returns the hotels array

});

const initialState = {
    hotels: [],  // Array to hold hotel data
    selectedHotel: null,  // To hold a specific hotel when selected
    loading: false,  // Loading state for API calls
    error: null,  // Error state in case API call fails
};

const hotelSlice = createSlice({
    name: 'hotels',
    initialState,
    reducers: {
        addHotel: (state, action) => {
            state.hotels.push(action.payload);
        },
        updateHotel: (state, action) => {
            const index = state.hotels.findIndex(hotel => hotel.id === action.payload.id);
            if (index !== -1) {
                state.hotels[index] = action.payload;
            }
        },
        deleteHotel: (state, action) => {
            state.hotels = state.hotels.filter(hotel => hotel.id !== action.payload);
        },
        selectHotel: (state, action) => {
            state.selectedHotel = state.hotels.find(hotel => hotel.id === action.payload);
        },
        clearSelectedHotel: (state) => {
            state.selectedHotel = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchHotels.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchHotels.fulfilled, (state, action) => {
                // console.log('Fetched hotels:', action.payload);  // Debugging the payload

                state.hotels = action.payload;
                state.loading = false;
            })
            .addCase(fetchHotels.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const {
    addHotel,
    updateHotel,
    deleteHotel,
    selectHotel,
    clearSelectedHotel,
} = hotelSlice.actions;

export default hotelSlice.reducer;
