import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './routes/Home';
import { GoogleOAuthProvider } from "@react-oauth/google"
import Destinations from './routes/Destinations';
import Header from './components/Header';
import Tourpackeges from './routes/Tourpackeges';
import Footer from "./components/Footer";
import Dashboard from './routes/Dashboard';
import Wishlist from './routes/Wishlist';
import Booking from './routes/Booking';
import Login from './routes/Login';
import Register from './routes/Register';
import Aboutus from './routes/Aboutus';
import Singletour from './routes/Singletour';
import Contactus from './routes/Contactus';
import PrivateRoute from './components/PrivateRoute';
import PrivateAdminRoute from './components/PrivateAdminRoute';
import AdminLogin from './admin/AdminLogin';
import AdminResistration from './admin/AdminResistration';
import AdminPanel from './admin/AdminPanel';
import Setting from './routes/Setting';
import AddProduct from './routes/AddProduct';
import { useSelector } from 'react-redux';
import Security from './routes/Security';
import ScrollToTop from './components/ScrollToTop';
import BookingCompleted from './routes/BookingCompleted';
import ForgetPassword from './routes/ForgetPassword';
import Error404Page from './routes/Error404Page';
import Scrolltotopui from './components/Scrolltotopui';
import Thankyou from './routes/Thankyou';
import SingleDesinations from './routes/SingleDesinations';
import PrivacyPolicy from './routes/PrivacyPolicy';
import TermandConditon from './routes/TermandCondition';
import Hotels from './routes/Hotels';
import Cabs from './routes/Cabs';
import Packages from './routes/Packages';
import SingleHotelPage from './routes/SingleHotelPage';
import AddHotel from './routes/AddHotel';
import AddDestination from './routes/AddDestination';


function App() {
  // const [loggedIn, setLoggedIn] = useState(false);

  const loggedIn = useSelector((state) => state.user.isLoggedIn);
  const adminLoggedIn = useSelector((state) => state.admin.isAdminLoggedIn);
  // console.log(loggedIn);
  // console.log(adminLoggedIn);

  return (
    <GoogleOAuthProvider clientId="821107050229-gh8ebq4ipt2u615mj0i3rg6vp734054i.apps.googleusercontent.com">

      <Router>
        <Header loggedIn={loggedIn} adminLoggedIn={adminLoggedIn} />
        <ScrollToTop />

        <Routes>
          {/* Public Routes */}
          <Route path="/admin" element={<AdminLogin />} />
          <Route path="/adminregister" element={<AdminResistration />} />

          {/* Admin Protected Routes */}
          <Route path="/adminpanel" element={
            <PrivateAdminRoute adminLoggedIn={adminLoggedIn}>
              <AdminPanel />
            </PrivateAdminRoute>
          } />

          {/* User Routes */}
          <Route path="/" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <Home />
            </PrivateRoute>
          } />
          <Route path="/login" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <Login />
            </PrivateRoute>
          } />
          <Route path="/register" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <Register />
            </PrivateRoute>
          } />
          <Route path="/destinations" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <Destinations />
            </PrivateRoute>
          } />
          <Route path="/tour-packages" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <Tourpackeges />
            </PrivateRoute>
          } />
          <Route path="/dashboard" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <Dashboard adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} />
            </PrivateRoute>
          } />
          <Route path="/setting" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <Setting adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} />
            </PrivateRoute>
          } />

          <Route path="/wishlist" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <Wishlist adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} />
            </PrivateRoute>
          } />
          <Route path="/booking" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <Booking adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} />
            </PrivateRoute>
          } />
          <Route path="/security" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <Security adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} />
            </PrivateRoute>
          } />
          <Route path="/about-us" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <Aboutus />
            </PrivateRoute>
          } />
          <Route path="/tour-packages/:id" element={

            <Singletour />
          } />

          <Route path="/booking-completed" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <BookingCompleted />
            </PrivateRoute>
          } />
          <Route path="/contact-us" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <Contactus />
            </PrivateRoute>
          } />
          <Route path="/addproduct" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <AddProduct adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} />
            </PrivateRoute>
          } />
          <Route path="/addhotel" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <AddHotel adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} />
            </PrivateRoute>
          } />
          <Route path="/adddestination" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <AddDestination adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} />
            </PrivateRoute>
          } />
          <Route path="/forgot-password" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <ForgetPassword adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} />
            </PrivateRoute>
          } />
          <Route path="/packages" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <Packages adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} />
            </PrivateRoute>
          } />
          <Route path="/thank-you" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <Thankyou adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} />
            </PrivateRoute>
          } />
          <Route path="/destinations/:id" element={
            <SingleDesinations adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} />
          } />
          <Route path="/privacy-policy" element={
            <PrivacyPolicy adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} />
          } />
          <Route path="/terms-conditions" element={
            <TermandConditon adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} />
          } />
          <Route path="/hotels" element={
            <Hotels adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} />
          } />
          <Route path="/cabs" element={
            <Cabs adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} />
          } />
          <Route path="/hotel/:id" element={
            <SingleHotelPage adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} />
          } />
          <Route path='*' element={<Error404Page />} />
        </Routes>
        <Footer />
        <Scrolltotopui />
      </Router>
    </GoogleOAuthProvider>

  );
}

export default App;
