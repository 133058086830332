import React from 'react'
import { Link } from 'react-router-dom'

const About = () => {
    return (
        <section className="p-top-90 p-bottom-90 bg-gray-gradient" >
            <div className="container">
                <div className="row g-0">
                    <div className="col-12 col-xl-6 order-1 order-xl-0">
                        <div className="pe-xl-5">
                            <div className="image-info image-info-right image-info-vertical">
                                <div className="vertical-title">
                                    <small className="ls-2">
                                        <strong className="text-primary fw-semibold">Since 1993</strong> - <strong className="text-body fw-semibold">31 years</strong> of experience
                                    </small>
                                </div>
                                <div className="image-center">
                                    <img src="assets/img/about/a2.jpg" srcSet="assets/img/about/a2@2x.jpg 2x" className="rounded w-100" alt="" />
                                </div>
                                <div className="info-top-right">
                                    <div className="vertical-award rounded shadow-sm">
                                        <div className="award-content">
                                            <img src="assets/img/logos/trip-best.png" srcSet="assets/img/logos/trip-best@2x.png 2x" className="w-100" alt="" />
                                        </div>
                                        <div className="award-footer">
                                            <small>Tripadvisor</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-6 order-0 order-xl-1">
                        <div className="mb-5 mb-xl-0">
                            <div className="block-title">
                                <small className="sub-title">About us</small>
                                <h2 className="h1 title">Tripnow Travel Agency</h2>
                            </div>
                            <p>
                                Tripnow Travel Agency offers unique and memorable tours, providing rich experiences
                                in the beautiful country of Tripnow. With a professional and dedicated team,
                                we are committed to bringing you wonderful, safe, and exciting journeys,
                                helping you explore the beauty of the world.
                            </p>
                            <ul className="strength-list row g-0 pt-2">
                                <li className="col-12 col-md-6">
                                    <div className="strength-item">
                                        <span className="strength-icon">
                                            <i className="hicon hicon-150 hicon-confirmation-instant"></i>
                                        </span>
                                        <strong className="strength-title">Great travel experiences</strong>
                                    </div>
                                </li>
                                <li className="col-12 col-md-6">
                                    <div className="strength-item">
                                        <span className="strength-icon">
                                            <i className="hicon hicon-150 hicon-menu-price-display"></i>
                                        </span>
                                        <strong className="strength-title">Competitive pricing offers</strong>
                                    </div>
                                </li>
                                <li className="col-12 col-md-6">
                                    <div className="strength-item">
                                        <span className="strength-icon">
                                            <i className="hicon hicon-150 hicon-pay-on-checkin"></i>
                                        </span>
                                        <strong className="strength-title">Flexible itinerary options</strong>
                                    </div>
                                </li>
                                <li className="col-12 col-md-6">
                                    <div className="strength-item">
                                        <span className="strength-icon">
                                            <i className="hicon hicon-150 hicon-agoda-price-guarante"></i>
                                        </span>
                                        <strong className="strength-title">Leading industry reputation</strong>
                                    </div>
                                </li>
                            </ul>
                            <div className="pt-3">
                                <Link to="/about-us" className="btn btn-primary mnw-180">
                                    <span>Read more</span>
                                    <i className="hicon hicon-flights-one-ways"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About