import React from 'react'
import { Link } from 'react-router-dom'

function BookingCompleted() {
    return (
        <div className="p-top-90 p-bottom-90 bg-gray-gradient aos-init aos-animate" data-aos="fade">

            {/* <!-- Title --> */}
            <section className="container">
                <div className="d-lg-flex align-items-lg-end pb-4">
                    <div className="block-title me-auto">
                        <small className="sub-title">Booking</small>
                        <h1 className="display-5 title">Completed</h1>
                    </div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mt-3">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item"><Link to="#">Pages</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Completed</li>
                        </ol>
                    </nav>
                </div>
                <div className="booking-process fw-normal mb-5" data-booking-step="3">
                    <span className="circle-icon circle-icon-sm booking-step step-1">1</span>
                    <span className="circle-icon circle-icon-sm booking-step step-2">2</span>
                    <span className="circle-icon circle-icon-sm booking-step step-3">3</span>
                </div>
            </section>
            {/* <!-- Title --> */}

            {/* <!-- Completed --> */}
            <section className="container">
                <div className="card border-0 shadow-sm aos-init aos-animate" data-aos="fade">
                    <div className="card-body">
                        <div className="border-bottom pb-4 mb-4">
                            <h2 className="h3 ff-primary mb-0 text-body-emphasis">Booking Successful</h2>
                        </div>
                        <p>
                            Your booking was sent successfully. We will contact you very soon!
                            <br />
                            Thank you for your booking!
                        </p>
                        <Link to="/" className="fw-medium">
                            <span>Homepage</span>
                            <i className="hicon hicon-thin-circle-arrow-left"></i>
                        </Link>
                    </div>
                </div>
            </section>
            {/* <!-- /Completed --> */}

        </div>)
}

export default BookingCompleted