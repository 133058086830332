import React from 'react'
import { Link } from 'react-router-dom'

const Aboutustitle = () => {
    return (
        <section className="hero">
            <div className="hero-bg">
                <img src="./assets/img/about/a6.jpg" srcset="./assets/img/about/a6@2x.jpg 2x" alt="" />
            </div>
            <div className="bg-content container">
                <div className="hero-page-title">
                    <span className="hero-sub-title">Get to Know Us</span>
                    <h1 className="display-3 hero-title">
                        About Us
                    </h1>
                </div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">About us</li>
                    </ol>
                </nav>
            </div>
        </section>)
}

export default Aboutustitle