import React from 'react';
import Tourpackegestitle from '../components/Tourpackegestitle';
import Tourpackegestours from '../components/Tourpackegestours';
import Destinationsabout from '../components/Destinationsabout';
// import Travelinsight from '../components/Travelinsight';
import { useLocation } from 'react-router-dom';

const Tourpackeges = () => {
    const location = useLocation();

    const { destination } = location.state || {}; // recieving from destination-destination component

    const { data } = location.state || {}; // recieving from hero component
    // console.log(data);

    // If destination is undefined, set destinationName to an empty string or another fallback value
    const destinationName = destination ? destination.name : "TripNow";
    const filter = true

    return (
        <main>
            <Tourpackegestitle destination={destination} packages={data} />
            <Tourpackegestours destination={destination} packages={data} filter={filter} />
            <Destinationsabout destination={destinationName} />
        </main>
    );
};

export default Tourpackeges;
