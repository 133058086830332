import React, { useEffect } from 'react'
import HotelHeroSection from '../components/HotelHeroSection'
import HotelsCards from '../components/HotelsCards'
import HotelsAbout from '../components/HotelsAbout'
import { useDispatch, useSelector } from 'react-redux';
import { fetchHotels } from '../redux/hotelSclice';

const Hotels = () => {
    const dispatch = useDispatch();
    const { hotels, loading, error } = useSelector(state => state.hotels)
    useEffect(() => {
        dispatch(fetchHotels());
    }, [dispatch]);



    if (!loading && !error && hotels.length > 0) {

        return (

            <main>
                <HotelHeroSection />
                <HotelsCards hotels={hotels} />
                <HotelsAbout />

            </main>
        )
    }
    else {
        <p>No data found</p>
    }
}

export default Hotels