import React, { useEffect } from 'react';
import Destinationtitle from '../components/Destinationtitle';
import Destinationsdestination from '../components/Destinationsdestination';
import Destinationsabout from '../components/Destinationsabout';
// import Travelinsight from '../components/Travelinsight';
// import DestinationSearch from '../components/DestinationSearch';
// import PopularTopics from '../components/PopularTopics';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDestination } from '../redux/destinationSlice';

const Destinations = () => {
    const name = "TripNow"
    const dispatch = useDispatch();
    const { destinations, loading, error } = useSelector(state => state.destination);

    useEffect(() => {
        dispatch(fetchDestination());
    }, [dispatch]);

    // console.log("Destinations:", destinations);
    // console.log("Loading:", loading);
    // console.log("Error:", error);
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    if (!loading && destinations.length > 0) {
        return <div>
            <main>
                <Destinationtitle destinations={destinations} loading={loading} error={error} />
                {/* <DestinationSearch destinations={destinations} loading={loading} error={error} /> */}
                <Destinationsdestination destinations={destinations} loading={loading} error={error} />
                <Destinationsabout destination={name} destinations={destinations} loading={loading} error={error} />
                {/* <Travelinsight destinations={destinations} loading={loading} error={error} /> */}
                {/* <PopularTopics destinations={destinations} loading={loading} error={error} /> */}
            </main>
        </div>
    }

}

export default Destinations;
