import React from 'react';
import { Link } from 'react-router-dom';

const Travelinsight = () => {
    return (
        <section className="p-top-90 p-bottom-90 bg-gray-gradient">
            <div className="container">
                {/* Title */}
                <div className="d-xl-flex align-items-xl-center mb-4">
                    <div className="block-title me-auto">
                        <small className="sub-title">Useful information</small>
                        <h2 className="h1 title">Moliva Travel Insight</h2>
                    </div>
                    <div className="extra-info mt-xl-3">
                        <strong>+500</strong>
                        <span>Useful articles about Moliva</span>
                    </div>
                </div>
                {/* /Title */}

                {/* Post list */}
                <div className="row">
                    {Array.from({ length: 9 }, (_, index) => (
                        <div className="col-12 col-xl-4" key={index}>
                            {/* Post */}
                            <div className="post-mini mb-4 pb-lg-2">
                                <Link to="/single-post" className="post-img rounded">
                                    <figure className="image-hover image-hover-overlay image-hover-scale">
                                        <img src="./assets/img/blog/b1.jpg" srcSet="./assets/img/blog/b1@2x.jpg 2x" alt="" />
                                    </figure>
                                </Link>
                                <div className="post-content">
                                    <h3 className="post-title">
                                        <Link to="/single-post">Top 10 Must-Visit Tourist Spots in Moliva</Link>
                                    </h3>
                                    <div className="post-ext">
                                        <div className="post-date">
                                            <i className="hicon hicon-menu-calendar"></i>
                                            <span>Jun 30 2024</span>
                                        </div>
                                        <div className="post-comment">
                                            <i className="hicon hicon-chat"></i>
                                            <span>36</span>
                                        </div>
                                        <div className="post-view">
                                            <i className="hicon hicon-installment-graph"></i>
                                            <span>754</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Post */}
                        </div>
                    ))}
                </div>
                {/* /Post list */}

                <div className="text-center fw-normal text-secondary mt-2">
                    <Link to="/post-list" className="fw-medium">
                        <span>View all posts</span>
                        <i className="hicon hicon-flights-one-ways"></i>
                    </Link>
                </div>
            </div>
        </section>
    );
}

export default Travelinsight;
