import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide.min.css';
import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

const teamMembers = [
    { name: "John Doe", role: "CEO", image: "t2.jpg" },
    { name: "Emily Smith", role: "Marketing Manager", image: "t1.jpg" },
    { name: "Alex Mark", role: "Tour guide", image: "t3.jpg" },
    { name: "Tom Cruise", role: "Financial management", image: "t5.jpg" },
    { name: "Anna Lee", role: "Tour Operator", image: "t4.jpg" },
];

const SocialIcon = ({ path, ariaLabel, to }) => (
    <li className="social-item">
        <Link to={to} aria-label={ariaLabel}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="none" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d={path}></path>
            </svg>
        </Link>
    </li>
);

const Meetourteam = () => {
    const sliderRef = useRef(null);

    useEffect(() => {
        const splide = new Splide(sliderRef.current, {
            perPage: 4,
            gap: '1.5rem',
            padding: 0,
            speed: 1000,
            type: 'loop',
            focus: 0,
            pagination: false,  // Disable pagination dots
            arrows: true,       // Enable arrows
            breakpoints: {
                1200: {
                    perPage: 1,
                    padding: { left: 0, right: '280px' },
                    arrows: true,
                },
                992: {
                    perPage: 1,
                    padding: { left: 0, right: '250px' },
                    arrows: true,
                },
                576: {
                    perPage: 1,
                    gap: '0.75rem',
                    padding: 0,
                    arrows: true,
                },
            },
        });

        splide.on('mounted', () => {
            console.log('Slider mounted');
            sliderRef.current.style.visibility = 'visible';
        });

        splide.mount();

        return () => {
            splide.destroy();
        };
    }, []);

    return (
        <section className="p-top-90 p-bottom-90 bg-gray-gradient">
            <div className="container">
                <div ref={sliderRef} id="testimonial-slider" className="team-slider splide mb-5" style={{ visibility: 'hidden' }}>
                    <div className="block-title text-center pb-3">
                        <small className="sub-title">Enthusiastic people</small>
                        <h2 className="h1 title">Meet our team</h2>
                    </div>
                    <div className="splide__arrows splide__arrows__right">
                        <button className="splide__arrow splide__arrow--prev me-2" style={{ backgroundColor: "rgb(81 67 217)", height: "40px", width: "40px", opacity: 1, borderRadius: "5px" }}>
                            <i className="hicon hicon-edge-arrow-left"></i>
                        </button>
                        <button className="splide__arrow splide__arrow--next" style={{ backgroundColor: "rgb(81 67 217)", height: "40px", width: "40px", opacity: 1, borderRadius: "5px" }}>
                            <i className="hicon hicon-edge-arrow-right"></i>
                        </button>
                    </div>
                    <div className="splide__track pt-2 pb-2">
                        <ul className="splide__list">
                            {teamMembers.map((member, index) => (
                                <li key={index} className="splide__slide">
                                    <div className="team shadow-sm rounded hover-effect">
                                        <img src={`/assets/img/team/${member.image}`} srcSet={`/assets/img/team/${member.image.replace('.jpg', '@2x.jpg')} 2x`} alt={member.name} />
                                        <div className="team-info">
                                            <h3 className="h5 team-name">{member.name}</h3>
                                            <span className="team-city">{member.role}</span>
                                            <ul className="social-list d-inline-block mb-0">
                                                <SocialIcon path="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" ariaLabel="Facebook" to="#" />
                                                <SocialIcon path="M4 4l11.733 16h4.267l-11.733 -16 M4 20l6.768 -6.768m2.46 -2.46l6.772 -6.772" ariaLabel="Twitter" to="#" />
                                                <SocialIcon path="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z M8 11l0 5 M8 8l0 .01 M12 16l0 -5 M16 16v-3a2 2 0 0 0 -4 0" ariaLabel="LinkedIn" to="#" />
                                                <SocialIcon path="M8 20l4 -9 M10.7 14c.437 1.263 1.43 2 2.55 2c2.071 0 3.75 -1.554 3.75 -4a5 5 0 1 0 -9.7 1.7 M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" ariaLabel="Dribbble" to="#" />
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            {/* Custom CSS for Arrow Positioning */}
            <style>{`
                .splide__arrow--prev,
                .splide__arrow--next {
                    position: absolute;
                    top: 10px; /* Adjust to move to the top left or bottom left */
                    left: 10px;
                    z-index: 10;
                }

                .splide__arrow--next {
                    left: 50px; /* Adjust the space between prev and next buttons */
                }

                .splide__arrow svg {
                    width: 30px;
                    height: 30px;
                    fill: #333; /* Adjust color for arrows */
                }

                .splide__arrow:hover svg {
                    fill: #007bff; /* Optional: Add hover effect */
                }
            `}</style>
        </section>
    );
};

export default Meetourteam;
