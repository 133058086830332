import React from 'react'

const CabsAbout = () => {
    return (
        <section className="p-top-90 p-bottom-90 bg-gray-gradient">
            <div className="container">
                <div className="row g-0">
                    <div className="col-12 col-xl-6 order-1 order-xl-0">
                        {/* Image */}
                        <div className="pe-xl-5">
                            <div className="image-info image-info-right image-info-vertical mb-5">
                                <div className="vertical-title">
                                    <small>
                                        <strong className="text-primary fw-semibold">TRIPNOW CABS</strong> - Luxury and Comfort
                                    </small>
                                </div>
                                <div className="image-center rounded">
                                    <img src="./assets/img/about/a3.jpg" srcSet="./assets/img/about/a3@2x.jpg 2x" className="w-100" alt="" />
                                </div>
                                <div className="info-top-right">
                                    <div className="vertical-award rounded shadow">
                                        <div className="award-content">
                                            <img src="./assets/img/logos/trip-top.png" srcSet="./assets/img/logos/trip-top@2x.png 2x" alt="" />
                                            <span className="award-title">Top-Rated Destinations</span>
                                        </div>
                                        <div className="award-footer">
                                            <small>Tripadvisor</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* /Image */}
                    </div>
                    <div className="col-12 col-xl-6 order-0 order-xl-1">
                        {/* Content */}
                        <div className="pt-4 mb-5 mb-xl-0">
                            <div className="block-title">
                                <small className="sub-title">TRIPNOW CABS Exclusive</small>
                                <h2 className="h1 title">About TRIPNOW CABS</h2>
                            </div>
                            <p>
                                TRIPNOW CABS redefines luxury, offering premium accommodations with world-class service.
                                Set in the heart of mesmerizing destinations, our hotels blend comfort, elegance, and convenience to provide
                                an unparalleled travel experience. Whether you're seeking a serene escape or an adventurous getaway, we have something for every traveler.
                            </p>
                            <p>
                                With top-tier amenities and a commitment to quality, TRIPNOW CABS ensures that your stay is unforgettable.
                                Enjoy gourmet dining, rejuvenating spas, and breathtaking views—all designed to make your experience extraordinary.
                                Discover the essence of exceptional hospitality with TRIPNOW CABS.
                            </p>
                        </div>
                        {/* /Content */}
                    </div>
                </div>
                {/* Overview */}
                <div className="row g-3 mb-0">
                    <div className="col-6 col-xl-3">
                        <div className="mini-card card-simple card-mobile-small">
                            <span className="card-icon">
                                <i className="hicon hicon-poi"></i>
                            </span>
                            <div className="card-content">
                                <small className="card-desc">Location</small>
                                <h3 className="h5 card-title">Varanasi City</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-xl-3">
                        <div className="mini-card card-simple card-mobile-small">
                            <span className="card-icon">
                                <i className="hicon hicon-wintry-mix-snow-sleet"></i>
                            </span>
                            <div className="card-content">
                                <small className="card-desc">Winter (avg)</small>
                                <h3 className="h5 card-title">41°F/5°C</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-xl-3">
                        <div className="mini-card card-simple card-mobile-small">
                            <span className="card-icon">
                                <i className="hicon hicon-partly-cloudy-day"></i>
                            </span>
                            <div className="card-content">
                                <small className="card-desc">Summer (avg)</small>
                                <h3 className="h5 card-title">68°F/20°C</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-xl-3">
                        <div className="mini-card card-simple card-mobile-small">
                            <span className="card-icon">
                                <i className="hicon hicon-time-clock"></i>
                            </span>
                            <div className="card-content">
                                <small className="card-desc">Time Zone</small>
                                <h3 className="h5 card-title">GMT+2</h3>
                            </div>
                        </div>
                    </div>
                </div>
                {/* /Overview */}
            </div>
        </section>)
}

export default CabsAbout