import React from 'react';
import { Link } from 'react-router-dom';

const Error404Page = () => {
    return (
        <section className="vh-100">
            <div className="d-flex align-items-center text-center h-100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-xl-8 col-lg-10">
                            <div className="block-title">
                                <h1 className="display-2 title">Error 404</h1>
                            </div>
                            <h2>Oops! Page Not Found</h2>
                            <div className="fs-5 text-secondary">
                                <p>Sorry, but the page you are looking for does not exist, has been removed, name changed, or is temporarily unavailable.</p>
                            </div>
                            <Link to="/" className="fw-medium">
                                <span>Homepage</span>
                                <i className="hicon hicon-thin-circle-arrow-left fsm-5"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Error404Page;
