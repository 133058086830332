import React from 'react'
import { Link } from 'react-router-dom'

const Booking = () => {
    return (
        <section className="p-top-120 p-bottom-120 bg-dark-blue" >
            <div className="container">
                <div className="row justify-content-center text-center">
                    <div className="col-12 col-xl-8">
                        <div className="text-center">
                            <div className="block-title">
                                <small className="sub-title text-light">Start Exploring</small>
                                <h2 className="h1 title text-white">Are you ready for adventures to Tripnow?</h2>
                            </div>
                            <div className="d-md-inline-flex align-items-md-center">
                                <Link to="/tour-packages" className="btn btn-primary mnw-180 me-2 ms-2 mt-4">
                                    <i className="hicon hicon hicon-bold hicon-menu-calendar"></i>
                                    <span> Book tours</span>
                                </Link>
                                <Link to="/contact-us" className="btn btn-outline-light mnw-180 me-2 ms-2 mt-4">
                                    <i className="hicon hicon hicon-email-envelope"></i>
                                    <span> Contact Us</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Booking