import React from 'react'
import { Link } from 'react-router-dom'


const HotelsCards = ({ hotels }) => {
    // console.log(hotels[0].image);

    const getImageSrc = (picture) => {
        // console.log(picture);

        if (!picture) {
            return 'assets/img/tours/t1.jpg'; // Default image
        }
        // Simple check to see if picture is a URL
        return picture.startsWith('http') ? picture : `/images/hotelImage/${picture}`;
    };


    return (
        <section className="p-top-90 p-bottom-90 bg-gray-gradient">
            <div className="container">

                <div className="row" style={{ justifyContent: 'center' }}>

                    <div >
                        {/* <!-- Tour grid --> */}
                        <div className="tour-grid mb-5 mb-xl-0">
                            <div className="row" >
                                {hotels.map((hotel) => (
                                    <div key={hotel._id} className="col-12 col-xxl-4 col-md-6">
                                        <div className="tour-item rounded shadow-sm hover-effect mb-4">
                                            <div className="tour-img">
                                                <Link to={`/hotel/${hotel.slug.split(" ").join("-").toLowerCase()}`}>
                                                    <figure className="image-hover image-hover-overlay">
                                                        <img
                                                            src={`/${getImageSrc(hotel.image[0].imageAdress)}`}
                                                            srcSet={`/${getImageSrc(hotel.image[0].imageAdress)}`}
                                                            alt={hotel.name}
                                                        />
                                                        <i className="hicon hicon-plus-thin image-hover-icon"></i>
                                                    </figure>
                                                </Link>
                                            </div>
                                            <div className="tour-content">
                                                <h3 className="tour-title">
                                                    <Link to={`/hotel/${hotel.slug.split(" ").join("-").toLowerCase()}`}>{hotel.name}</Link>
                                                </h3>
                                                <div className="tour-itinerary">
                                                    <span><i className="hicon hicon-menu-calendar"></i> 3 days</span>
                                                    <span><i className="hicon hicon-flights-pin"></i> 3 destinations </span>
                                                </div>
                                                <div className="inline-review">
                                                    <span className="review-score">4.5</span>
                                                    <span className="star-rate-view star-rate-size-sm me-2"><span className="star-value rate-50"></span></span>
                                                    <small className="review-total"><span>(200 reviews)</span></small>
                                                </div>
                                                <div className="tour-booking">
                                                    <Link to={`/hotel/${hotel.slug.split(" ").join("-").toLowerCase()}`} className="btn btn-primary btn-uppercase" >
                                                        View details <i className="hicon hicon-flights-one-ways"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <nav aria-label="Page navigation" className="pt-3 text-center">
                                <ul className="pagination pagination-circle d-inline-flex mb-0">
                                    <li className="page-item">
                                        <Link className="page-link" href="#">
                                            <i className="hicon hicon-edge-arrow-left"></i>
                                        </Link>
                                    </li>
                                    <li className="page-item"><Link className="page-link" href="#">1</Link></li>
                                    <li className="page-item">
                                        <button className="page-link" href="#">
                                            <i className="hicon hicon-edge-arrow-right"></i>
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        {/* <!-- /Tour grid --> */}
                    </div>
                </div>
                {/* <!-- /Tours & Filters --> */}
            </div>

        </section>)
}

export default HotelsCards 