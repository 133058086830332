import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Async thunk to fetch destinations
export const fetchDestination = createAsyncThunk(
    'destination/fetchDestination',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get('/api/destinationdata');
            return response.data; // Assuming the backend returns the destinations array
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const initialState = {
    destinations: [], // Array to hold destination data
    selectedDestination: null, // To hold a specific destination when selected
    loading: false, // Loading state for API calls
    error: null, // Error state in case API call fails
};

const destinationSlice = createSlice({
    name: "destinations",
    initialState,
    reducers: {
        addDestination: (state, action) => {
            state.destinations.push(action.payload);
        },
        updateDestination: (state, action) => {
            const index = state.destinations.findIndex(destination => destination.id === action.payload.id);
            if (index !== -1) {
                state.destinations[index] = action.payload;
            }
        },
        deleteDestination: (state, action) => {
            state.destinations = state.destinations.filter(destination => destination.id !== action.payload);
        },
        selectDestination: (state, action) => {
            state.selectedDestination = state.destinations.find(destination => destination.id === action.payload);
        },
        clearSelectedDestination: (state) => {
            state.selectedHotel = null;
        },
        // Reducer to select a specific destination
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDestination.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchDestination.fulfilled, (state, action) => {
                state.loading = false;
                state.destinations = action.payload;
            })
            .addCase(fetchDestination.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || "Failed to fetch destinations";
            });
    }
});

export const { addDestination, updateDestination, deleteDestination, selectDestination, clearSelectedDestination } = destinationSlice.actions;
export default destinationSlice.reducer;
