import React from 'react'
import { Link } from 'react-router-dom'


const CabsCards = () => {
    return (
        <section className="p-top-90 p-bottom-90 bg-gray-gradient">
            <div className="container">

                <div className="row" style={{ justifyContent: 'center' }}>

                    <div >
                        {/* <!-- Tour grid --> */}
                        <div className="tour-grid mb-5 mb-xl-0">
                            <div className="row" >
                                <div className="col-12 col-xxl-4 col-md-6">

                                    <div className="tour-item rounded shadow-sm hover-effect mb-4">
                                        <div className="tour-img">
                                            <Link to="./single-tour.html">
                                                <figure className="image-hover image-hover-overlay ">
                                                    <img src="./assets/img/tours/t1.jpg" srcSet="./assets/img/tours/t1@2x.jpg 2x" alt="" />
                                                    <i className="hicon hicon-plus-thin image-hover-icon"></i>
                                                </figure>
                                            </Link>

                                        </div>
                                        <div className="tour-content">
                                            <h3 className="tour-title">
                                                <Link to="./single-tour.html">Explore the castle and ancient village in Karda</Link>
                                            </h3>
                                            <div className="tour-itinerary">
                                                <span><i className="hicon hicon-menu-calendar"></i> 3 days</span>
                                                <span><i className="hicon hicon-flights-pin"></i> 3 Destinations</span>
                                            </div>
                                            <div className="inline-review">
                                                <span className="review-score">4.9</span>
                                                <span className="star-rate-view star-rate-size-sm me-2"><span className="star-value rate-50"></span></span>
                                                <small className="review-total"><span>(231 reviews)</span></small>
                                            </div>
                                            <div className="tour-booking">

                                                <Link to="./single-tour.html" className="btn btn-primary btn-uppercase">
                                                    View details <i className="hicon hicon-flights-one-ways"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- /Tour --> */}
                                </div>
                                <div className="col-12 col-xxl-4 col-md-6">

                                    <div className="tour-item rounded shadow-sm hover-effect mb-4">
                                        <div className="tour-img">
                                            <Link to="./single-tour.html">
                                                <figure className="image-hover image-hover-overlay ">
                                                    <img src="./assets/img/tours/t1.jpg" srcSet="./assets/img/tours/t1@2x.jpg 2x" alt="" />
                                                    <i className="hicon hicon-plus-thin image-hover-icon"></i>
                                                </figure>
                                            </Link>

                                        </div>
                                        <div className="tour-content">
                                            <h3 className="tour-title">
                                                <Link to="./single-tour.html">Explore the castle and ancient village in Karda</Link>
                                            </h3>
                                            <div className="tour-itinerary">
                                                <span><i className="hicon hicon-menu-calendar"></i> 3 days</span>
                                                <span><i className="hicon hicon-flights-pin"></i> 3 Destinations</span>
                                            </div>
                                            <div className="inline-review">
                                                <span className="review-score">4.9</span>
                                                <span className="star-rate-view star-rate-size-sm me-2"><span className="star-value rate-50"></span></span>
                                                <small className="review-total"><span>(231 reviews)</span></small>
                                            </div>
                                            <div className="tour-booking">

                                                <Link to="./single-tour.html" className="btn btn-primary btn-uppercase">
                                                    View details <i className="hicon hicon-flights-one-ways"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- /Tour --> */}
                                </div>
                                <div className="col-12 col-xxl-4 col-md-6">

                                    <div className="tour-item rounded shadow-sm hover-effect mb-4">
                                        <div className="tour-img">
                                            <Link to="./single-tour.html">
                                                <figure className="image-hover image-hover-overlay ">
                                                    <img src="./assets/img/tours/t1.jpg" srcSet="./assets/img/tours/t1@2x.jpg 2x" alt="" />
                                                    <i className="hicon hicon-plus-thin image-hover-icon"></i>
                                                </figure>
                                            </Link>

                                        </div>
                                        <div className="tour-content">
                                            <h3 className="tour-title">
                                                <Link to="./single-tour.html">Explore the castle and ancient village in Karda</Link>
                                            </h3>
                                            <div className="tour-itinerary">
                                                <span><i className="hicon hicon-menu-calendar"></i> 3 days</span>
                                                <span><i className="hicon hicon-flights-pin"></i> 3 Destinations</span>
                                            </div>
                                            <div className="inline-review">
                                                <span className="review-score">4.9</span>
                                                <span className="star-rate-view star-rate-size-sm me-2"><span className="star-value rate-50"></span></span>
                                                <small className="review-total"><span>(231 reviews)</span></small>
                                            </div>
                                            <div className="tour-booking">

                                                <Link to="./single-tour.html" className="btn btn-primary btn-uppercase">
                                                    View details <i className="hicon hicon-flights-one-ways"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- /Tour --> */}
                                </div>
                                <div className="col-12 col-xxl-4 col-md-6">

                                    <div className="tour-item rounded shadow-sm hover-effect mb-4">
                                        <div className="tour-img">
                                            <Link to="./single-tour.html">
                                                <figure className="image-hover image-hover-overlay ">
                                                    <img src="./assets/img/tours/t1.jpg" srcSet="./assets/img/tours/t1@2x.jpg 2x" alt="" />
                                                    <i className="hicon hicon-plus-thin image-hover-icon"></i>
                                                </figure>
                                            </Link>

                                        </div>
                                        <div className="tour-content">
                                            <h3 className="tour-title">
                                                <Link to="./single-tour.html">Explore the castle and ancient village in Karda</Link>
                                            </h3>
                                            <div className="tour-itinerary">
                                                <span><i className="hicon hicon-menu-calendar"></i> 3 days</span>
                                                <span><i className="hicon hicon-flights-pin"></i> 3 Destinations</span>
                                            </div>
                                            <div className="inline-review">
                                                <span className="review-score">4.9</span>
                                                <span className="star-rate-view star-rate-size-sm me-2"><span className="star-value rate-50"></span></span>
                                                <small className="review-total"><span>(231 reviews)</span></small>
                                            </div>
                                            <div className="tour-booking">

                                                <Link to="./single-tour.html" className="btn btn-primary btn-uppercase">
                                                    View details <i className="hicon hicon-flights-one-ways"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- /Tour --> */}
                                </div>
                                <div className="col-12 col-xxl-4 col-md-6">

                                    <div className="tour-item rounded shadow-sm hover-effect mb-4">
                                        <div className="tour-img">
                                            <Link to="./single-tour.html">
                                                <figure className="image-hover image-hover-overlay ">
                                                    <img src="./assets/img/tours/t1.jpg" srcSet="./assets/img/tours/t1@2x.jpg 2x" alt="" />
                                                    <i className="hicon hicon-plus-thin image-hover-icon"></i>
                                                </figure>
                                            </Link>

                                        </div>
                                        <div className="tour-content">
                                            <h3 className="tour-title">
                                                <Link to="./single-tour.html">Explore the castle and ancient village in Karda</Link>
                                            </h3>
                                            <div className="tour-itinerary">
                                                <span><i className="hicon hicon-menu-calendar"></i> 3 days</span>
                                                <span><i className="hicon hicon-flights-pin"></i> 3 Destinations</span>
                                            </div>
                                            <div className="inline-review">
                                                <span className="review-score">4.9</span>
                                                <span className="star-rate-view star-rate-size-sm me-2"><span className="star-value rate-50"></span></span>
                                                <small className="review-total"><span>(231 reviews)</span></small>
                                            </div>
                                            <div className="tour-booking">

                                                <Link to="./single-tour.html" className="btn btn-primary btn-uppercase">
                                                    View details <i className="hicon hicon-flights-one-ways"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- /Tour --> */}
                                </div>
                                <div className="col-12 col-xxl-4 col-md-6">

                                    <div className="tour-item rounded shadow-sm hover-effect mb-4">
                                        <div className="tour-img">
                                            <Link to="./single-tour.html">
                                                <figure className="image-hover image-hover-overlay ">
                                                    <img src="./assets/img/tours/t1.jpg" srcSet="./assets/img/tours/t1@2x.jpg 2x" alt="" />
                                                    <i className="hicon hicon-plus-thin image-hover-icon"></i>
                                                </figure>
                                            </Link>

                                        </div>
                                        <div className="tour-content">
                                            <h3 className="tour-title">
                                                <Link to="./single-tour.html">Explore the castle and ancient village in Karda</Link>
                                            </h3>
                                            <div className="tour-itinerary">
                                                <span><i className="hicon hicon-menu-calendar"></i> 3 days</span>
                                                <span><i className="hicon hicon-flights-pin"></i> 3 Destinations</span>
                                            </div>
                                            <div className="inline-review">
                                                <span className="review-score">4.9</span>
                                                <span className="star-rate-view star-rate-size-sm me-2"><span className="star-value rate-50"></span></span>
                                                <small className="review-total"><span>(231 reviews)</span></small>
                                            </div>
                                            <div className="tour-booking">

                                                <Link to="./single-tour.html" className="btn btn-primary btn-uppercase">
                                                    View details <i className="hicon hicon-flights-one-ways"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- /Tour --> */}
                                </div>
                                <div className="col-12 col-xxl-4 col-md-6">

                                    <div className="tour-item rounded shadow-sm hover-effect mb-4">
                                        <div className="tour-img">
                                            <Link to="./single-tour.html">
                                                <figure className="image-hover image-hover-overlay ">
                                                    <img src="./assets/img/tours/t1.jpg" srcSet="./assets/img/tours/t1@2x.jpg 2x" alt="" />
                                                    <i className="hicon hicon-plus-thin image-hover-icon"></i>
                                                </figure>
                                            </Link>

                                        </div>
                                        <div className="tour-content">
                                            <h3 className="tour-title">
                                                <Link to="./single-tour.html">Explore the castle and ancient village in Karda</Link>
                                            </h3>
                                            <div className="tour-itinerary">
                                                <span><i className="hicon hicon-menu-calendar"></i> 3 days</span>
                                                <span><i className="hicon hicon-flights-pin"></i> 3 Destinations</span>
                                            </div>
                                            <div className="inline-review">
                                                <span className="review-score">4.9</span>
                                                <span className="star-rate-view star-rate-size-sm me-2"><span className="star-value rate-50"></span></span>
                                                <small className="review-total"><span>(231 reviews)</span></small>
                                            </div>
                                            <div className="tour-booking">

                                                <Link to="./single-tour.html" className="btn btn-primary btn-uppercase">
                                                    View details <i className="hicon hicon-flights-one-ways"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- /Tour --> */}
                                </div>
                                <div className="col-12 col-xxl-4 col-md-6">

                                    <div className="tour-item rounded shadow-sm hover-effect mb-4">
                                        <div className="tour-img">
                                            <Link to="./single-tour.html">
                                                <figure className="image-hover image-hover-overlay ">
                                                    <img src="./assets/img/tours/t1.jpg" srcSet="./assets/img/tours/t1@2x.jpg 2x" alt="" />
                                                    <i className="hicon hicon-plus-thin image-hover-icon"></i>
                                                </figure>
                                            </Link>

                                        </div>
                                        <div className="tour-content">
                                            <h3 className="tour-title">
                                                <Link to="./single-tour.html">Explore the castle and ancient village in Karda</Link>
                                            </h3>
                                            <div className="tour-itinerary">
                                                <span><i className="hicon hicon-menu-calendar"></i> 3 days</span>
                                                <span><i className="hicon hicon-flights-pin"></i> 3 Destinations</span>
                                            </div>
                                            <div className="inline-review">
                                                <span className="review-score">4.9</span>
                                                <span className="star-rate-view star-rate-size-sm me-2"><span className="star-value rate-50"></span></span>
                                                <small className="review-total"><span>(231 reviews)</span></small>
                                            </div>
                                            <div className="tour-booking">

                                                <Link to="./single-tour.html" className="btn btn-primary btn-uppercase">
                                                    View details <i className="hicon hicon-flights-one-ways"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- /Tour --> */}
                                </div>
                                <div className="col-12 col-xxl-4 col-md-6">

                                    <div className="tour-item rounded shadow-sm hover-effect mb-4">
                                        <div className="tour-img">
                                            <Link to="./single-tour.html">
                                                <figure className="image-hover image-hover-overlay ">
                                                    <img src="./assets/img/tours/t1.jpg" srcSet="./assets/img/tours/t1@2x.jpg 2x" alt="" />
                                                    <i className="hicon hicon-plus-thin image-hover-icon"></i>
                                                </figure>
                                            </Link>

                                        </div>
                                        <div className="tour-content">
                                            <h3 className="tour-title">
                                                <Link to="./single-tour.html">Explore the castle and ancient village in Karda</Link>
                                            </h3>
                                            <div className="tour-itinerary">
                                                <span><i className="hicon hicon-menu-calendar"></i> 3 days</span>
                                                <span><i className="hicon hicon-flights-pin"></i> 3 Destinations</span>
                                            </div>
                                            <div className="inline-review">
                                                <span className="review-score">4.9</span>
                                                <span className="star-rate-view star-rate-size-sm me-2"><span className="star-value rate-50"></span></span>
                                                <small className="review-total"><span>(231 reviews)</span></small>
                                            </div>
                                            <div className="tour-booking">

                                                <Link to="./single-tour.html" className="btn btn-primary btn-uppercase">
                                                    View details <i className="hicon hicon-flights-one-ways"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- /Tour --> */}
                                </div>

                            </div>
                            <nav aria-label="Page navigation" className="pt-3 text-center">
                                <ul className="pagination pagination-circle d-inline-flex mb-0">
                                    <li className="page-item">
                                        <Link className="page-link" href="#">
                                            <i className="hicon hicon-edge-arrow-left"></i>
                                        </Link>
                                    </li>
                                    <li className="page-item"><Link className="page-link" href="#">1</Link></li>
                                    <li className="page-item">
                                        <Link className="page-link" href="#">
                                            <i className="hicon hicon-edge-arrow-right"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        {/* <!-- /Tour grid --> */}
                    </div>
                </div>
                {/* <!-- /Tours & Filters --> */}
            </div>
        </section>)
}

export default CabsCards