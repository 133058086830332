import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk for fetching product data
export const fetchProducts = createAsyncThunk('product/fetchProducts', async () => {
    console.log("fetchProducts action called...");

    const response = await axios.get('/api/productdata');
    return response.data;  // Assuming the backend returns the products array
});

const initialState = {
    products: [],  // Array to hold product data
    selectedProduct: null,  // To hold a specific product when selected
    loading: false,  // Loading state for API calls
    error: null,  // Error state in case API call fails
};

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        addProduct: (state, action) => {
            state.products.push(action.payload);
        },
        updateProduct: (state, action) => {
            const index = state.products.findIndex(product => product.id === action.payload.id);
            if (index !== -1) {
                state.products[index] = action.payload;
            }
        },
        deleteProduct: (state, action) => {
            state.products = state.products.filter(product => product.id !== action.payload);
        },
        selectProduct: (state, action) => {
            state.selectedProduct = state.products.find(product => product.id === action.payload);
        },
        clearSelectedProduct: (state) => {
            state.selectedProduct = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProducts.pending, (state) => {
                if (!state.loading) { // Ensure `loading` only sets to true if it was previously false
                    state.loading = true;
                    state.error = null;
                }
            })
            .addCase(fetchProducts.fulfilled, (state, action) => {
                state.products = action.payload;
                state.loading = false;
            })
            .addCase(fetchProducts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const {
    addProduct,
    updateProduct,
    deleteProduct,
    selectProduct,
    clearSelectedProduct,
} = productSlice.actions;

export default productSlice.reducer;
