import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ForgetPassword = () => {
    // State to manage the email input
    const [email, setEmail] = useState('');

    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission
        // Here, you can send the email to your backend/API
        console.log('Email submitted:', email);
        // Reset the email input after submission if needed
        setEmail('');
    };

    return (
        <div>
            <section className="p-top-90 p-bottom-90 bg-gray-gradient">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-xl-5 col-lg-7 col-md-9">
                            <div className="card border-0 shadow-sm p-xl-2">
                                <div className="card-body">
                                    <div className="border-bottom mb-4">
                                        <h1 className="h2 text-body-emphasis">Forgot password</h1>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className="border-bottom pb-4">
                                            <div className="mb-4">
                                                <label htmlFor="txtEmail" className="form-label">Email<span className="text-danger">*</span></label>
                                                <input
                                                    className="form-control shadow-sm"
                                                    id="txtEmail"
                                                    name="email"
                                                    type="email" // Ensure the input type is email
                                                    value={email} // Set input value from state
                                                    onChange={(e) => setEmail(e.target.value)} // Update state on input change
                                                    required
                                                />
                                            </div>
                                            <p>
                                                <strong className="fw-medium">Note:</strong> A link will be sent to your mail, you need to click on it to create a new password.
                                            </p>
                                            <button className="btn btn-primary w-100" type="submit" id="btnSubmit">
                                                <i className="hicon hicon-email-envelope"></i>
                                                <span>Send link</span>
                                            </button>
                                        </div>
                                        <div className="mt-4">
                                            <span>Remember Password? <Link to="/login">Login</Link></span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ForgetPassword;
