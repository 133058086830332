import React from 'react';
import { Link } from 'react-router-dom';
import UserProfileCard from '../components/UserProfileCard';
import { useSelector } from 'react-redux';
import { useEffect, useRef, useMemo } from 'react';
import { fetchProducts } from '../redux/productSlice';
import { useDispatch } from 'react-redux';

const Dashboard = ({ loggedIn, adminLoggedIn, setLoggedIn, setAdminLoggedIn }) => {
    const userData = useSelector((state) => state.user.userData);
    // console.log("userData", userData);
    const adminData = useSelector((state) => state.admin.adminData);
    // console.log(adminData);

    const dispatch = useDispatch();
    const { products, loading } = useSelector((state) => state.product);
    const dataLoadedRef = useRef(false);

    useEffect(() => {
        dispatch(fetchProducts());
    }, [dispatch]);

    useEffect(() => {
        if (!loading && products.length > 0 && !dataLoadedRef.current) {
            // console.log("All data loaded:", products);
            dataLoadedRef.current = true;
        }
    }, [loading, products])

    const userProducts = useMemo(() => {
        if (!userData || !userData.orders || !products) return [];
        const orderProductIds = userData.orders.map(order => order.product);
        return products.filter(product => orderProductIds.includes(product._id));
    }, [userData, products]);
    // console.log("userData product:", userProducts);

    return (
        <main>
            <div className="p-top-90 p-bottom-90 bg-gray-gradient" data-aos="fade">
                <section className="container">
                    <div className="d-lg-flex align-items-lg-end pb-4">
                        <div className="block-title me-auto">
                            <small className="sub-title">My Account</small>
                            <h1 className="display-5 title">Dashboard</h1>
                        </div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mt-3">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
                            </ol>
                        </nav>
                    </div>
                </section>

                <section className="container">
                    <div className="row g-0 g-xl-8">
                        <div className="col-12 col-xl-9">
                            <div className="pe-xl-5">
                                <div className="pb-4">
                                    <div className="row g-3 g-md-4">
                                        {
                                            adminLoggedIn ? <>
                                                <div className="col-12 col-md-6">
                                                    <Link to="/dashboard" className="mini-card card-hover hover-effect shadow-sm rounded p-4">
                                                        <span className="card-icon">
                                                            <i className="icon hicon hicon-bold hicon-menu-calendar "></i>
                                                        </span>
                                                        <div className="card-content">
                                                            <h2 className="h4 card-title">{adminData.bookedProducts.length} Booking</h2>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <Link to="/wishlist" className="mini-card card-hover hover-effect shadow-sm rounded p-4">
                                                        <span className="card-icon">
                                                            <i className="hicon hicon hicon-bold hicon-menu-favorite "></i>
                                                        </span>
                                                        <div className="card-content">
                                                            <h2 className="h4 card-title">{adminData.products.length} Packages</h2>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <Link to="/booking" className="mini-card card-hover hover-effect shadow-sm rounded p-4">
                                                        <span className="card-icon">
                                                            <i className="icon hicon hicon-bold hicon-menu-calendar "></i>
                                                        </span>
                                                        <div className="card-content">
                                                            <h2 className="h4 card-title">{adminData.hotels.length} Hotels</h2>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <Link to="/wishlist" className="mini-card card-hover hover-effect shadow-sm rounded p-4">
                                                        <span className="card-icon">
                                                            <i className="hicon hicon hicon-bold hicon-menu-favorite "></i>
                                                        </span>
                                                        <div className="card-content">
                                                            <h2 className="h4 card-title">{userData.cart.length} Cabs</h2>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </> : <>
                                                <div className="col-12 col-md-6">
                                                    <Link to="/booking" className="mini-card card-hover hover-effect shadow-sm rounded p-4">
                                                        <span className="card-icon">
                                                            <i className="icon hicon hicon-bold hicon-menu-calendar "></i>
                                                        </span>
                                                        <div className="card-content">
                                                            <h2 className="h4 card-title">{userData.orders.length} Booking</h2>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <Link to="/wishlist" className="mini-card card-hover hover-effect shadow-sm rounded p-4">
                                                        <span className="card-icon">
                                                            <i className="hicon hicon hicon-bold hicon-menu-favorite "></i>
                                                        </span>
                                                        <div className="card-content">
                                                            <h2 className="h4 card-title">{userData.cart.length} Wishlist</h2>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="card border-0 shadow-sm">
                                    <div className="card-body">
                                        <div className="d-flex align-items-center justify-content-between border-bottom pb-4 mb-4">
                                            <h2 className="h3 ff-primary mb-0 text-body-emphasis">Recent Booking</h2>
                                            <Link to="/booking" className="d-inline-block fw-medium">
                                                <span>All Booking</span>
                                                <i className="hicon hicon-flights-one-ways"></i>
                                            </Link>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead className="text-center">
                                                    <tr className="table-light">
                                                        <th scope="col"><strong>ID</strong></th>
                                                        <th scope="col"><strong>Tour</strong></th>
                                                        <th scope="col"><strong>Amount</strong></th>
                                                        <th scope="col"><strong>Date</strong></th>
                                                        <th scope="col"><strong>Status</strong></th>
                                                        <th scope="col"><strong>Details</strong></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        userProducts.map((product, i) => {
                                                            const productID = product._id;
                                                            const orderData = userData.orders.filter(order => order.product === productID); // Accessing data from userData
                                                            const date = new Date(orderData[0].departureDate);
                                                            const options = { year: 'numeric', month: 'short', day: '2-digit' };
                                                            const formattedDate = date.toLocaleDateString('en-US', options);
                                                            return (

                                                                <tr key={i}>
                                                                    <td className="text-center"><span>{i + 1}</span></td>
                                                                    <td className="text-nowrap">
                                                                        <div className="d-flex flex-column">
                                                                            <Link to="/booking-details" className="link-dark link-hover"><strong>{product.name}</strong></Link>
                                                                            <small className="text-nowrap d-block">code:{product.tourCode}</small>
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-center"><strong><sup>$</sup>{product.price}</strong></td>
                                                                    <td className="text-center"><small className="text-nowrap">{formattedDate}</small></td>
                                                                    <td className="text-center"><span className="booking-status booking-unpaid">Unpaid</span></td>
                                                                    <td className="text-center">
                                                                        <Link to="/booking-details" className="circle-icon circle-icon-link circle-icon-sm" title="Detail booking">
                                                                            <i className="hicon hicon-flights-one-ways"></i>
                                                                        </Link>
                                                                    </td>
                                                                </tr>)
                                                        })}

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="text-center">
                                            <Link to="#" className="fw-medium">
                                                <i className="hicon hicon-bold hicon-refresh"></i>
                                                <span>Load More</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-3">
                            <div className="card border-0 shadow-sm sticky-top sticky-top-120 d-none d-xl-block z-0">
                                <UserProfileCard adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} setAdminLoggedIn={setAdminLoggedIn} setLoggedIn={setLoggedIn} />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main>
    );
};

export default Dashboard;
