import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import adminReducer from "./adminSlice"
import productReducer from "./productSlice"
import destinationReducer from './destinationSlice'; // Import the destination slice reducer
import hotelReducer from './hotelSclice';

const store = configureStore({
    reducer: {
        user: userReducer,
        admin: adminReducer,
        product: productReducer,
        destination: destinationReducer,
        hotels: hotelReducer
    },
});

export default store;
