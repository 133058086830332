import React from 'react';
import { Link } from 'react-router-dom';

const Singletourreview = () => {
    return (
        <div className="card border-0 shadow-sm p-xl-2">
            <div className="card-body">
                <div className="border-bottom mb-4 pb-4">
                    <h2 className="text-body-emphasis mb-0">
                        Reviews
                    </h2>
                </div>
                <div className="row">
                    <div className="col-12 col-xxl-5 col-lg-6">
                        {/* Overall rating */}
                        <div className="horizontal-review rounded border h-100">
                            <h2 className="h4 review-title pb-4 mb-4 lh-base">Overall rating</h2>
                            <div className="review-content">
                                <h3 className="review-score">4.9</h3>
                                <div className="review-total">
                                    <span className="star-rate-view"><span className="star-value rate-50"></span></span>
                                    <span><strong className="text-body">2,394</strong> reviews</span>
                                </div>
                            </div>
                            <div className="review-label">
                                <small>Excellent Score</small>
                            </div>
                        </div>
                        {/* /Overall rating */}
                    </div>
                    <div className="col-12 col-xxl-7 col-lg-6">
                        {/* Review summary */}
                        <div className="review-summary rounded border h-100">
                            <h3 className="h4 review-title">Review summary</h3>
                            <ul className="review-content">
                                <li>
                                    <span className="review-label">Transportation:</span>
                                    <div className="review-score">
                                        <strong>4.5</strong>
                                        <span className="star-rate-view star-rate-size-sm"><span className="star-value rate-45"></span></span>
                                    </div>
                                </li>
                                <li>
                                    <span className="review-label">Value for money:</span>
                                    <div className="review-score">
                                        <strong>4.5</strong>
                                        <span className="star-rate-view star-rate-size-sm"><span className="star-value rate-45"></span></span>
                                    </div>
                                </li>
                                <li>
                                    <span className="review-label">Support services:</span>
                                    <div className="review-score">
                                        <strong>4.5</strong>
                                        <span className="star-rate-view star-rate-size-sm"><span className="star-value rate-45"></span></span>
                                    </div>
                                </li>
                                <li>
                                    <span className="review-label">Organization:</span>
                                    <div className="review-score">
                                        <strong>4.0</strong>
                                        <span className="star-rate-view star-rate-size-sm"><span className="star-value rate-40"></span></span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        {/* /Review summary */}
                    </div>
                </div>
                {/* Review list */}
                <div className="card rounded border mb-4 mt-4">
                    <div className="card-body">
                        <div className="border-bottom d-flex align-items-center pb-4 mb-4">
                            <h3 className="h4 me-auto mb-0">Recent reviews</h3>
                            <strong>2,394 reviews</strong>
                        </div>
                        <div className="review-list">
                            {/* Review item */}
                            <div className="review-item">
                                <div className="review-client">
                                    <figure className="review-avatar">
                                        <img src="./assets/img/avatars/a1.jpg" srcSet="assets/img/avatars/a1@2x.jpg 2x" alt="" className="rounded-circle" />
                                    </figure>
                                    <div className="review-name">
                                        <strong>John Doe <small>(Italy)</small></strong>
                                        <span className="star-rate-view star-rate-size-sm"><span className="star-value rate-45"></span></span>
                                    </div>
                                </div>
                                <p>
                                    "The 5-day tour of Noriva was fantastic! Stunning landscapes, well-organized activities,
                                    and friendly guides made the trip unforgettable. Highly recommend!"
                                </p>
                                <div className="review-date">
                                    <small>February 9, 2023</small> - <span className="review-verify">Verified booking</span>
                                </div>
                            </div>
                            {/* Review item */}
                            <div className="review-item">
                                <div className="review-client">
                                    <figure className="review-avatar">
                                        <img src="./assets/img/avatars/a2.jpg" srcSet="assets/img/avatars/a2@2x.jpg 2x" alt="" className="rounded-circle" />
                                    </figure>
                                    <div className="review-name">
                                        <strong>Emily Smith <small>(USA)</small></strong>
                                        <span className="star-rate-view star-rate-size-sm"><span className="star-value rate-45"></span></span>
                                    </div>
                                </div>
                                <p>
                                    "An incredible journey through Noriva! From breathtaking mountain views to exciting water sports,
                                    every day was packed with adventure and beauty. A must-visit!"
                                </p>
                                <div className="review-date">
                                    <small>February 9, 2023</small> - <span className="review-verify">Verified booking</span>
                                </div>
                            </div>
                            {/* More review items can go here */}
                            {/* Pagination */}
                            <nav aria-label="Page navigation" className="text-center">
                                <ul className="pagination pagination-circle d-inline-flex mb-0">
                                    <li className="page-item">
                                        <Link className="page-link" to="#">
                                            <i className="hicon hicon-edge-arrow-left"></i>
                                        </Link>
                                    </li>
                                    <li className="page-item"><Link className="page-link" to="#">1</Link></li>
                                    <li className="page-item">
                                        <Link className="page-link" to="#">
                                            <i className="hicon hicon-edge-arrow-right"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Singletourreview;
