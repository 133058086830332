import React from 'react';
import { Link } from 'react-router-dom';

const DestinationsDestination = ({ destinations, loading, error }) => {
    // console.log(destinations);

    return (
        <section className="p-top-90 p-bottom-90 bg-gray-gradient">
            <div className="container">
                {/* Title */}
                <div className="d-xl-flex align-items-xl-center pb-4">
                    <div className="block-title me-auto">
                        <small className="sub-title">Explore Moliva</small>
                        <h2 className="h1 title">Destinations in Moliva</h2>
                    </div>
                    <div className="extra-info mt-3">
                        <strong className="text-body">+50</strong>
                        <span className="text-secondary">Attractive destinations</span>
                    </div>
                </div>
                {/* /Title */}

                {/* Destination list */}
                <div className="row">
                    {
                        !loading && !error && destinations.length > 0 ? destinations.map((destination) => (
                            destination.products.length > 0 && <div className="col-12 col-xxl-3 col-xl-4 col-md-6" key={destination._id}>
                                {/* Destination */}
                                <Link
                                    to={`/destinations/${destination.slug.toLowerCase()}`}
                                    className="destination bottom-overlay hover-effect rounded mb-4"
                                >
                                    <figure className="image-hover image-hover-overlay">
                                        <img
                                            src="./assets/img/destinations/d1.jpg"
                                            srcSet="./assets/img/destinations/d1@2x.jpg 2x"
                                            alt="Noriva"
                                        />
                                        <i className="hicon hicon-plus-thin image-hover-icon"></i>
                                    </figure>
                                    <div className="bottom-overlay-content">
                                        <div className="destination-content">
                                            <div className="destination-info">
                                                <h3 className="destination-title">{destination.name}</h3>
                                                <span>{destination.products.length} tours</span>
                                            </div>
                                            <span className="circle-icon circle-icon-link">
                                                <i className="hicon hicon-flights-pin"></i>
                                            </span>
                                        </div>
                                    </div>
                                </Link>
                                {/* /Destination */}
                            </div>
                        ))
                            :
                            <p>data is loading</p>
                    }

                </div>
                {/* /Destination list */}

                {/* Pagination */}
                <nav aria-label="Page navigation" className="pt-3 text-center">
                    <ul className="pagination pagination-circle d-inline-flex mb-0">
                        <li className="page-item">
                            <Link className="page-link" to="#">
                                <i className="hicon hicon-edge-arrow-left"></i>
                            </Link>
                        </li>
                        <li className="page-item">
                            <Link className="page-link" to="#">
                                1
                            </Link>
                        </li>
                        <li className="page-item">
                            <Link className="page-link" to="#">
                                <i className="hicon hicon-edge-arrow-right"></i>
                            </Link>
                        </li>
                    </ul>
                </nav>
                {/* /Pagination */}
            </div>
        </section>
    );
};

export default DestinationsDestination;
