import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide.min.css';
import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setUserData, addToCart, removeFromCart } from '../redux/userSlice';
import axios from 'axios';

export const Othertours = ({ filterProducts, title, subtitle }) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const loggedIn = useSelector((state) => state.user.isLoggedIn);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userData = useSelector((state) => state.user.userData);

    const splideInstance = useRef(null);

    const getImageSrc = (picture) => {
        if (!picture) {
            return '/assets/img/avatars/a3@2x.jpg';
        }
        return picture.startsWith('http') ? picture : picture;
    };

    const onLikeProduct = async (productId) => {
        if (!loggedIn) {
            navigate("/login");
            return;
        }

        try {
            const response = await axios.post("/user/api/likedProduct", { productId });
            if (response.data.userData) {
                dispatch(setUserData(response.data.userData));
                setSuccessMessage("Product liked and added to your wishlist successfully!");
                setErrorMessage("");
            } else {
                dispatch(addToCart(productId));
                setSuccessMessage("Product liked and added to your wishlist successfully!");
                setErrorMessage("");
            }
        } catch (error) {
            if (error.response && error.response.status === 409) {
                try {
                    const response = await axios.post("/user/api/unlikedProduct", { productId });
                    if (response.data.userData) {
                        dispatch(setUserData(response.data.userData));
                        setSuccessMessage("Product removed from your wishlist successfully!");
                    } else {
                        dispatch(removeFromCart(productId));
                        setSuccessMessage("Product removed from your wishlist successfully!");
                    }
                } catch (error) {
                    setErrorMessage(error.response ? error.response.data.message : error.message);
                }
            } else {
                setErrorMessage(error.message);
                setSuccessMessage("");
            }
        }
    };

    useEffect(() => {
        if (filterProducts.length && !splideInstance.current) {
            splideInstance.current = new Splide('#other-tour-slider', {
                type: 'loop',
                perPage: 4,
                perMove: 1,
                pagination: false,
                arrows: true,
                gap: '1rem',
                breakpoints: {
                    1200: {
                        perPage: 2,
                    },
                    768: {
                        perPage: 1,
                    },
                },
            }).mount();
        }

        // Trigger resize event for Splide slider after initial render or state update
        const resizeSplide = () => {
            if (splideInstance.current) {
                splideInstance.current.refresh(); // Ensure Splide recalculates its layout
            }
        };

        window.addEventListener('resize', resizeSplide); // Listen for window resize to refresh layout

        return () => {
            if (splideInstance.current) {
                splideInstance.current.destroy(); // Cleanup Splide on component unmount
                splideInstance.current = null;
            }
            window.removeEventListener('resize', resizeSplide); // Remove event listener on cleanup
        };
    }, [filterProducts]); // Run this effect only when products are loaded

    return (
        <section className="p-top-90 p-bottom-90 bg-gray-gradient">
            <div className="container">
                <div id='other-tour-slider' className="other-tour-slider splide splide__pagination__start w-100">
                    {/* Title */}
                    <div className="d-xl-flex align-items-xl-end pb-3">
                        <div className="block-title me-auto">
                            <small className="sub-title">{subtitle || "Many tourists choose"}</small>
                            <h2 className="h1 title">{title || "Top TripNow Tours"}</h2>
                        </div>
                        <div className="splide__arrows splide__arrows__right">
                            <button className="splide__arrow splide__arrow--prev me-2" style={{ backgroundColor: "rgb(81 67 217)", height: "40px", width: "40px", opacity: 1, borderRadius: "5px" }}>
                                <i className="hicon hicon-edge-arrow-left"></i>
                            </button>
                            <button className="splide__arrow splide__arrow--next" style={{ backgroundColor: "rgb(81 67 217)", height: "40px", width: "40px", opacity: 1, borderRadius: "5px" }}>
                                <i className="hicon hicon-edge-arrow-right"></i>
                            </button>
                        </div>
                    </div>

                    {/* Success/Error Messages */}
                    {successMessage && (
                        <div className="alert alert-success">
                            {successMessage}
                        </div>
                    )}
                    {errorMessage && (
                        <div className="alert alert-danger">
                            {errorMessage}
                        </div>
                    )}

                    {/* Tours list */}
                    <div className="splide__track pt-2 pb-2">
                        <ul className="splide__list tour-grid">
                            {filterProducts.map((product) => (
                                <li className="splide__slide" key={product._id}>
                                    <div className="tour-item rounded shadow-sm hover-effect">
                                        <div className="tour-img">
                                            <Link to={`/tour-packages/${product.slug.split(' ').join("-").toLowerCase()}`} >
                                                <figure className="image-hover image-hover-overlay">
                                                    <img src={`/${getImageSrc(product.image[0].imageAdress)}`} alt={product.name} />
                                                    <i className="hicon hicon-plus-thin image-hover-icon"></i>
                                                </figure>
                                            </Link>
                                            <div className="tour-like">
                                                <button type="button" className="circle-icon like-icon liked" onClick={() => onLikeProduct(product._id)} style={{ color: userData?.cart?.includes(product._id) ? '#5143d' : 'grey' }}>
                                                    <i className="hicon hicon-favorite-filled"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="tour-content">
                                            <h3 className="tour-title">
                                                <Link to={`/tour-packages/${product.slug.split(' ').join("-").toLowerCase()}`} >{product.name}</Link>
                                            </h3>
                                            <div className="tour-itinerary">
                                                <span className="me-3"><i className="hicon hicon-menu-calendar"></i>{` ${product.iteracy?.curriculum.length} days`}</span>
                                                <span className="me-3"><i className="hicon hicon-flights-pin"></i>{` ${product.about?.highlight.length} Destinations`}</span>
                                            </div>
                                            <div className="inline-review">
                                                <span className="review-score">{product.rating}</span>
                                                <span className="star-rate-view star-rate-size-sm me-2"><span className="star-value rate-50"></span></span>
                                                <small className="review-total"><span>(231 reviews)</span></small>
                                            </div>
                                            <div className="tour-booking">
                                                <div className="tour-price">
                                                    <strong className="fs-4 fw-semibold"><sup>$</sup>{product.price}</strong>
                                                    <span className="fsm-4 text-secondary"><sup>$</sup><del>{product.price + 100}</del></span>
                                                </div>
                                                <Link to={`/tour-packages/${product.slug.split(' ').join("-").toLowerCase()}`} className="circle-icon circle-icon-link">
                                                    <i className="hicon hicon-flights-one-ways"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <p className="fw-normal text-secondary text-md-end mb-0 pt-4 mt-5 mt-md-0">
                    <Link to="/tour-packages" className="fw-medium">
                        <span>View all tours</span>
                        <i className="hicon hicon-flights-one-ways"></i>
                    </Link>
                </p>
            </div>
        </section>
    );
};
