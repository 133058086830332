import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import UserProfileCard from '../components/UserProfileCard';
import axios from 'axios';
import { addDestination } from '../redux/destinationSlice';

const AddDestination = ({ adminLoggedIn, loggedIn, setAdminLoggedIn, setLoggedIn }) => {
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        name: '',
        state: '',
        destinationType: '',
        destinationDescription: '',
        slug: '',
        images: [],
        rating: 0
    });
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleImageChange = (e) => {
        const selectedFiles = Array.from(e.target.files);

        if (selectedFiles.length + formData.images.length > 2) {
            alert("You can only upload up to 2 images.");
            return;
        }

        setFormData((prevFormData) => ({
            ...prevFormData,
            images: [...prevFormData.images, ...selectedFiles].slice(0, 2)
        }));
    };

    const handleImageRemove = (index) => {
        setFormData((prevData) => ({
            ...prevData,
            images: prevData.images.filter((_, i) => i !== index),
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();

        for (const key in formData) {
            if (key === 'images' && Array.isArray(formData[key])) {
                formData[key].forEach((file) => {
                    formDataToSend.append('images', file);
                });
            } else {
                formDataToSend.append(key, formData[key]);
            }
        }

        try {
            const response = await axios.post('/admin/api/addDestination', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            const newProduct = response.data;
            dispatch(addDestination(newProduct));

            setFormData({
                name: '',
                state: '',
                destinationType: '',
                destinationDescription: '',
                slug: '',
                images: [],
                rating: 0,
            });



            navigate('/adddestination');

        } catch (error) {
            if (error.response && error.response.status === 400) {
                // Handle 400 error: existing destination
                setErrorMessage(error.response.data.message); // Show the error message
            } else {
                // Handle other errors
                setErrorMessage('An error occurred while adding the destination.');
            }
        }
    };

    return (
        <main>
            <div className="p-top-90 p-bottom-90 bg-gray-gradient" data-aos="fade">
                <section className="container">
                    <div className="d-lg-flex align-items-lg-end pb-4">
                        <div className="block-title me-auto">
                            <small className="sub-title">My Account</small>
                            <h1 className="display-5 title">Settings</h1>
                        </div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mt-3">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Add Hotels</li>
                            </ol>
                        </nav>
                    </div>
                </section>

                <section className="container">
                    <div className="row g-0 g-xl-8">
                        <div className="col-12 col-xl-9">
                            <div className="pe-xl-4 me-xl-2">
                                <div className="card border-0 shadow-sm">
                                    <div className="card-body">
                                        <div className="d-flex align-items-center justify-content-between border-bottom pb-4 mb-4">
                                            <h2 className="h3 text-body-emphasis mb-0">Add Destination</h2>
                                        </div>
                                        {errorMessage && (
                                            <div className="alert alert-danger">
                                                {errorMessage}
                                            </div>
                                        )}
                                        <form className="form-container" onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <label>Name:</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Slug:</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="slug"
                                                    value={formData.slug}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Rating</label>
                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    name="rating"
                                                    value={formData.rating}
                                                    onChange={handleChange}
                                                    min={0}
                                                    max={5}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>State:</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="state"
                                                    value={formData.state}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Destination Type:</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="destinationType"
                                                    value={formData.destinationType}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Destination Description:</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="destinationDescription"
                                                    value={formData.destinationDescription}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Images:</label>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    accept="image/*"
                                                    multiple
                                                    onChange={handleImageChange}
                                                    name="images"
                                                />

                                                {formData.images.map((image, index) => (
                                                    <div key={index}>
                                                        <span>{image.name || 'Image selected'}</span>
                                                        <button type="button" onClick={() => handleImageRemove(index)}>Remove</button>
                                                    </div>
                                                ))}
                                            </div>

                                            <button type="submit" className="btn btn-primary">Submit</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-3">
                            {/* Account menu */}
                            <div className="card border-0 shadow-sm sticky-top sticky-top-120 d-none d-xl-block z-0">
                                <UserProfileCard adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} setAdminLoggedIn={setAdminLoggedIn} setLoggedIn={setLoggedIn} />
                            </div>
                            {/* Account menu */}
                        </div>
                    </div>
                </section>
            </div>
        </main>
    );
};

export default AddDestination;
