import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";

const Contactus = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        yourname: '',
        email: '',
        subject: '',
        message: '',
    });
    const [loading, setLoading] = useState(false); // Loading state

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading to true when form submission starts

        try {
            const response = await axios.post('/api/contact', formData);
            console.log('Form submitted:', response.data);
            alert('Message sent successfully!');
            setFormData({
                yourname: '',
                email: '',
                subject: '',
                message: '',
            });
            navigate("/thank-you");
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('Failed to send message. Please try again.');
        } finally {
            setLoading(false); // Set loading to false once the request completes
        }
    };

    return (
        <>
            <section className="hero">
                <div className="hero-bg">
                    <img src="./assets/img/about/a7.jpg" srcSet="./assets/img/about/a7@2x.jpg 2x" alt="" />
                </div>
                <div className="bg-content container">
                    <div className="hero-page-title">
                        <span className="hero-sub-title">Connect with us</span>
                        <h1 className="display-4 hero-title">Contact Us</h1>
                    </div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Contact</li>
                        </ol>
                    </nav>
                </div>
            </section>

            <section className="bg-gray-gradient p-bottom-90">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <div className="p-top-90 mb-4">
                                <div className="border-bottom pb-4 mb-4">
                                    <h2 className="me-auto mb-0">Ready to help you!</h2>
                                </div>
                                <p className="mb-4">We're here to help and answer any question you might have.</p>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="fw-medium mb-3">
                                            <i className="hicon hicon-flights-pin text-primary me-2"></i>
                                            <span>1063 Maruti Nagar Colony, Varanasi, India - 221011</span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="fw-medium mb-3">
                                            <i className="hicon hicon-telephone text-primary me-2"></i>
                                            <span>+91 9236975458</span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="fw-medium mb-3">
                                            <i className="hicon hicon-email-envelope text-primary me-2"></i>
                                            <span>tripnow.cc@gmail.com</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="form-contact rounded shadow-sm">
                                <form className="needs-validation" onSubmit={handleSubmit} noValidate>
                                    <div className="border-bottom pb-4 mb-4">
                                        <h2 className="text-white mb-0">Looking for any help?</h2>
                                    </div>
                                    <div className="form-floating mb-4">
                                        <input
                                            id="txtYourName"
                                            type="text"
                                            name="yourname"
                                            className="form-control shadow-sm"
                                            placeholder="Your Name"
                                            value={formData.yourname}
                                            onChange={handleChange}
                                            required
                                        />
                                        <label htmlFor="txtYourName">Your Name *</label>
                                    </div>
                                    <div className="form-floating mb-4">
                                        <input
                                            id="txtEmail"
                                            type="email"
                                            name="email"
                                            className="form-control shadow-sm"
                                            placeholder="Email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            required
                                        />
                                        <label htmlFor="txtEmail">Your Email *</label>
                                    </div>
                                    <div className="form-floating mb-4">
                                        <input
                                            id="txtSubject"
                                            type="text"
                                            name="subject"
                                            className="form-control shadow-sm"
                                            placeholder="Subject"
                                            value={formData.subject}
                                            onChange={handleChange}
                                            required
                                        />
                                        <label htmlFor="txtSubject">Subject *</label>
                                    </div>
                                    <div className="form-floating mb-4">
                                        <textarea
                                            id="txtMessage"
                                            name="message"
                                            className="form-control shadow-sm"
                                            placeholder="Message"
                                            style={{ height: '150px' }}
                                            value={formData.message}
                                            onChange={handleChange}
                                            required
                                        ></textarea>
                                        <label htmlFor="txtMessage">Message *</label>
                                    </div>
                                    <button type="submit" className="btn btn-light mnw-180" disabled={loading}>
                                        {loading ? 'Sending...' : (
                                            <>
                                                <i className="hicon hicon-email-envelope"></i>
                                                <span> Send message</span>
                                            </>
                                        )}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Contactus;
