import React from 'react'
import { Link } from 'react-router-dom'


const PrivacyPolicy = () => {
    return (
        <section className="p-top-90 p-bottom-90 bg-gray-gradient">

            <div className="container">
                <div className="mb-4">
                    <div className="row g-0 g-md-2 align-items-center">
                        <div className="col-12 col-xl-8 ">
                            {/* <!-- Title --> */}
                            <div className="block-title me-auto">
                                <h2 className="h1 title">Privacy Policy</h2>
                                <small className="sub-title">Last updated on 07-11-2024</small>
                            </div>
                            <div className="col-12">
                                <h3 className="h3 title">Introduction</h3>
                                <p ><Link to="/"><span style={{ color: "blue" }}>Tripow </span> </Link> values your privacy and is committed to protecting your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website, quantumwebs.in, or use our services.</p>
                            </div>
                            <div className="col-12">
                                <h3 className="h3 title">Information We Collect</h3>
                                <p ><span style={{ fontWeight: "500", opacity: "0.9" }}>Personal Information:  </span> We may collect personal information such as your name, email address, phone number, and any other details you provide when contacting us or using our services.</p>
                                <p ><span style={{ fontWeight: "500", opacity: "0.9" }}>Usage Data: </span> We collect information automatically when you visit our website, including your IP address, browser type, operating system, pages viewed, and the date and time of your visit.</p>
                                <p ><span style={{ fontWeight: "500", opacity: "0.9" }}>Cookies: </span> Our website uses cookies to enhance your experience. Cookies are small data files stored on your device. You can disable cookies through your browser settings, but this may affect your website experience.</p>
                            </div>
                            <div className="col-12">
                                <h3 className="h3 title">How We Use Your Information</h3>
                                <p ><span style={{ fontWeight: "500", opacity: "0.9" }}>To Provide Services: </span> We use your personal information to respond to your inquiries, provide services, and fulfill your requests.
                                </p>
                                <p ><span style={{ fontWeight: "500", opacity: "0.9" }}>Improvement: </span> We analyze usage data to improve our website, services, and user experience.</p>
                                <p ><span style={{ fontWeight: "500", opacity: "0.9" }}>Marketing: </span> With your consent, we may send you promotional materials and updates about our services. You can opt-out of these communications at any time.</p>
                                <p ><span style={{ fontWeight: "500", opacity: "0.9" }}>Security: </span> We use the information to protect our website and services from fraud, abuse, and unauthorized access.</p>
                            </div>
                            <div className="col-12">
                                <h3 className="h3 title">How We Share Your Information</h3>
                                <p ><span style={{ fontWeight: "500", opacity: "0.9" }}>Third-Party Service Providers: </span>We use your personal information to respond to your inquiries, provide services, and fulfill your requests.</p>
                                <p ><span style={{ fontWeight: "500", opacity: "0.9" }}>Legal Requirements: </span> We may disclose your information if required by law or in response to a valid legal request.</p>
                                <p ><span style={{ fontWeight: "500", opacity: "0.9" }}>Business Transfers: </span> If <span style={{ color: "blue" }}>TripNow</span> is involved in a merger, acquisition, or sale of assets, your information may be transferred as part of the transaction.</p>
                            </div>
                            <div className="col-12">
                                <h3 className="h3 title">Data Security
                                </h3>
                                <p >We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the Internet or electronic storage is completely secure, and we cannot guarantee its absolute security.</p>
                            </div>
                            <div className="col-12">
                                <h3 className="h3 title">Your Rights</h3>
                                <p ><span style={{ fontWeight: "500", opacity: "0.9" }}>Access and Update: </span>You have the right to access and update your personal information. You can contact us to review or correct your information.</p>
                                <p ><span style={{ fontWeight: "500", opacity: "0.9" }}>Opt-Out: </span> You can opt-out of receiving marketing communications from us by following the unsubscribe instructions included in each email or by contacting us directly.</p>
                                <p ><span style={{ fontWeight: "500", opacity: "0.9" }}>Data Deletion: </span>You can request the deletion of your personal information by contacting us. We will take reasonable steps to delete your information unless we are required to retain it by law.
                                </p>
                            </div>
                            <div className="col-12">
                                <h3 className="h3 title">Changes to This Privacy Policy</h3>
                                <p >We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website and updating the effective date. You are advised to review this Privacy Policy periodically for any changes.</p>
                            </div>
                            <div className="col-12">
                                <h3 className="h3 title">Contact Us</h3>
                                <p >If you have any questions about this Privacy Policy, please contact us at:</p>
                                <p style={{ fontWeight: "bold" }}>TripNow</p>
                                <div className="contact-info">
                                    <p>
                                        <span>1063 Maruti Nagar Colony, Varanasi, India - 221011</span>
                                    </p>
                                    <p>
                                        <span>+91 9236975458</span>
                                    </p>
                                    <p>
                                        <Link to="mailto:Booking@example.com">tripnow.cc@gmail.com</Link>
                                    </p>

                                </div>
                            </div>
                            {/* <!-- /Title --> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PrivacyPolicy