import React from 'react'

const Singletourdetails = ({ product }) => {
    console.log(product);

    return (
        <>
            <div className="card border-0 shadow-sm p-xl-2 mb-4" >
                <div className="card-body">
                    <div className="border-bottom mb-4 pb-4">
                        <h2 className="text-body-emphasis mb-0">About</h2>
                    </div>
                    <div className="d-lg-flex border-bottom pb-3 mb-4">
                        <div className="mnw-200">
                            <h3 className="h5">Highlights</h3>
                        </div>
                        <ul className="highlight-list checked">
                            {
                                product.about.highlight.map((data) => (
                                    <li>
                                        <span><strong>{data.title}:</strong> {data.highlightDescription}</span>
                                    </li>
                                ))
                            }

                        </ul>
                    </div>
                    <div className="d-lg-flex">
                        <div className="mnw-200">
                            <h3 className="h5">Description</h3>
                        </div>
                        <p>
                            {product.about.description}
                        </p>
                    </div>
                </div>
            </div>
            {/* <!-- /About --> */}
            {/* <!-- Photos --> */}
            <div className="card border-0 shadow-sm p-xl-2 mb-4" >
                <div className="card-body">
                    <div className="border-bottom mb-4 pb-4">
                        <h2 className="text-body-emphasis mb-0">
                            Photos
                        </h2>
                    </div>
                    <div className="row g-1 align-items-center">
                        <div className="col-12 col-xl-6">
                            <a href="/assets/img/tours/t7z.jpg" className="glightbox" data-glightbox="title:Explore Noriva Bay" data-gallery="tour-photos">
                                <figure className="image-hover image-hover-scale image-hover-overlay rounded mb-0">
                                    <img src="/assets/img/tours/t7.jpg" srcset="/assets/img/tours/t7@2x.jpg 2x" alt="" />
                                    <i className="hicon hicon-zoom-bold image-hover-icon fs-5"></i>
                                </figure>
                            </a>
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="row g-1 align-items-center">
                                <div className="col-6">
                                    <a href="/assets/img/tours/t8z.jpg" className="glightbox" data-glightbox="title:Explore Noriva Bay" data-gallery="tour-photos">
                                        <figure className="image-hover image-hover-scale image-hover-overlay rounded mb-0">
                                            <img src="/assets/img/tours/t8.jpg" srcset="/assets/img/tours/t8@2x.jpg 2x" alt="" />
                                            <i className="hicon hicon-zoom-bold image-hover-icon fs-5"></i>
                                        </figure>
                                    </a>
                                </div>
                                <div className="col-6">
                                    <a href="/assets/img/tours/t9z.jpg" className="glightbox" data-glightbox="title:Explore Noriva Bay" data-gallery="tour-photos">
                                        <figure className="image-hover image-hover-scale image-hover-overlay rounded mb-0">
                                            <img src="/assets/img/tours/t9.jpg" srcset="/assets/img/tours/t9@2x.jpg 2x" alt="" />
                                            <i className="hicon hicon-zoom-bold image-hover-icon fs-5"></i>
                                        </figure>
                                    </a>
                                </div>
                                <div className="col-6">
                                    <a href="/assets/img/tours/t10z.jpg" className="glightbox" data-glightbox="title:Explore Noriva Bay" data-gallery="tour-photos">
                                        <figure className="image-hover image-hover-scale image-hover-overlay rounded mb-0">
                                            <img src="/assets/img/tours/t10.jpg" srcset="/assets/img/tours/t10@2x.jpg 2x" alt="" />
                                            <i className="hicon hicon-zoom-bold image-hover-icon fs-5"></i>
                                        </figure>
                                    </a>
                                </div>
                                <div className="col-6">
                                    <a href="/assets/img/tours/t11z.jpg" className="glightbox" data-glightbox="title:Explore Noriva Bay" data-gallery="tour-photos">
                                        <figure className="image-hover image-hover-scale image-hover-overlay rounded mb-0">
                                            <img src="/assets/img/tours/t11.jpg" srcset="/assets/img/tours/t11@2x.jpg 2x" alt="" />
                                            <i className="hicon hicon-zoom-bold image-hover-icon fs-5"></i>
                                        </figure>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-xl-3">
                            <a href="/assets/img/tours/t12z.jpg" className="glightbox" data-glightbox="title:Explore Noriva Bay" data-gallery="tour-photos">
                                <figure className="image-hover image-hover-scale image-hover-overlay rounded mb-0">
                                    <img src="/assets/img/tours/t12.jpg" srcset="/assets/img/tours/t12@2x.jpg 2x" alt="" />
                                    <i className="hicon hicon-zoom-bold image-hover-icon fs-5"></i>
                                </figure>
                            </a>
                        </div>
                        <div className="col-6 col-xl-3">
                            <a href="/assets/img/tours/t13z.jpg" className="glightbox" data-glightbox="title:Explore Noriva Bay" data-gallery="tour-photos">
                                <figure className="image-hover image-hover-scale image-hover-overlay rounded mb-0">
                                    <img src="/assets/img/tours/t13.jpg" srcset="/assets/img/tours/t13@2x.jpg 2x" alt="" />
                                    <i className="hicon hicon-zoom-bold image-hover-icon fs-5"></i>
                                </figure>
                            </a>
                        </div>
                        <div className="col-12 col-xl-6">
                            <a href="/assets/img/tours/t14z.jpg" className="glightbox" data-glightbox="title:Explore Noriva Bay" data-gallery="tour-photos">
                                <figure className="image-hover image-hover-scale image-hover-overlay rounded mb-0">
                                    <img src="/assets/img/tours/t14.jpg" srcset="/assets/img/tours/t14@2x.jpg 2x" alt="" />
                                    <i className="hicon hicon-zoom-bold image-hover-icon fs-5"></i>
                                </figure>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- /Photos --> */}
            {/* <!-- Itinerary --> */}
            <div className="card border-0 shadow-sm p-xl-2 mb-4" >
                <div className="card-body">
                    <div className="border-bottom mb-4 pb-4">
                        <h2 className="text-body-emphasis mb-0">
                            Itinerary
                        </h2>
                    </div>
                    <p>
                        {product.iteracy.iteracyDescription}
                    </p>
                    <div className="accordion accordion-flush accordion-itinerary" id="acdItinerary">
                        {
                            product.iteracy.curriculum.map((data, i) => (
                                <div className="accordion accordion-flush accordion-itinerary" id="acdItinerary">
                                    <div className="accordion-item bg-transparent">
                                        <h2 className="accordion-header">

                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#acdContent1${i + 1}`} aria-expanded="false" aria-controls="acdContent1">
                                                <p className='mb-0'>
                                                    <strong className="rounded-1">Day {i + 1}:</strong>
                                                    <span>{data.title}</span>
                                                </p>
                                            </button>

                                        </h2>
                                        <div id={`acdContent1${i + 1}`} className="accordion-collapse collapse" data-bs-parent="#acdItinerary">
                                            <div className="accordion-body">
                                                {data.curriculumDescription}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            {/* <!-- /Itinerary --> */}
            {/* <!-- Policy --> */}
            <div className="card border-0 shadow-sm p-xl-2 mb-4" >
                <div className="card-body">
                    <div className="border-bottom mb-4 pb-4">
                        <h2 className="text-body-emphasis mb-0">
                            Policy
                        </h2>
                    </div>
                    <div className="d-lg-flex border-bottom pb-3 mb-4">
                        <div className="mnw-200">
                            <h3 className="h5">Price included</h3>
                        </div>
                        <div>
                            <ul className="highlight-list checked">
                                {
                                    product.policy.priceIncluded.map((data) => (
                                        data.checked &&
                                        <li>
                                            <span><strong>{data.title}:</strong> {data.description}</span>
                                        </li>
                                    ))
                                }
                            </ul>
                            <ul className="highlight-list unchecked">
                                {
                                    product.policy.priceIncluded.map((data) => (
                                        !data.checked &&
                                        <li>
                                            <span><strong>{data.title}:</strong> {data.description}</span>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="d-lg-flex border-bottom pb-3 mb-4">
                        <div className="mnw-200">
                            <h3 className="h5">For children</h3>
                        </div>
                        <ul className="highlight-list checked">
                            {
                                product.policy.forChildren.map((data) => (
                                    data.checked &&
                                    <li>
                                        <span><strong>{data.title}:</strong> {data.description}</span>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className="d-lg-flex">
                        <div className="mnw-200">
                            <h3 className="h5">Other terms</h3>
                        </div>
                        <p>
                            {product.policy.OtherTerms}
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Singletourdetails