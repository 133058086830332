import React from 'react'
import { Link } from 'react-router-dom'

const Tourtypes = () => {
    return (
        <section className="p-top-90 p-bottom-90 bg-gray-gradient">
            <div className="container">
                {/* <!-- Types --> */}
                <div className="row g-3 g-xl-4">
                    <div className="col-12 col-xl-3 col-md-6">
                        {/* <!-- Title --> */}
                        <div className="info-card shadow-sm rounded h-100 active">
                            <div className="block-title">
                                <small className="sub-title card-title">Flexible tours</small>
                                <h2 className="h1 title card-title lh-xs">Explore your way</h2>
                            </div>
                            <p className="card-desc">Explore Moliva your way with incredible trips and captivating experiences.</p>
                            <div className="card-desc mt-3">
                                You need <Link to="/contact-us" className="link-light"><abbr title="Go to contact page" className="fw-semibold">advice</abbr></Link>?
                            </div>
                        </div>
                        {/* <!-- /Title --> */}
                    </div>
                    <div className="col-12 col-xl-3 col-md-6">
                        <Link to="/tour-packages" className="info-card hover-effect shadow-sm rounded h-100">
                            <div className="card-icon">
                                <i className="hicon hicon-family-with-teens"></i>
                            </div>
                            <h3 className="h4 card-title">Group tours</h3>
                            <p className="card-desc">Join our group tours to explore stunning destinations with like-minded travelers. Fun and camaraderie guaranteed.</p>
                            <span className="card-link">
                                <span>View tours</span>
                                <i className="hicon hicon-flights-one-ways"></i>
                            </span>
                        </Link>
                    </div>
                    <div className="col-12 col-xl-3 col-md-6">
                        <Link to="/tour-packages" className="info-card hover-effect shadow-sm rounded h-100">
                            <div className="card-icon">
                                <i className="hicon hicon-regular-travel-protection"></i>
                            </div>
                            <h3 className="h4 card-title">Private tours</h3>
                            <p className="card-desc">Enjoy personalized experiences with our private tours. Perfect for families, couples, or friends seeking exclusivity.</p>
                            <span className="card-link">
                                <span>View tours</span>
                                <i className="hicon hicon-flights-one-ways"></i>
                            </span>
                        </Link>
                    </div>
                    <div className="col-12 col-xl-3 col-md-6">
                        <Link to="/contact-us" className="info-card hover-effect shadow-sm rounded h-100">
                            <div className="card-icon">
                                <i className="hicon hicon-tours"></i>
                            </div>
                            <h3 className="h4 card-title">Tailor-Made tours</h3>
                            <p className="card-desc">Customize your dream vacation with our tailor-made tours. Create unique itineraries that suit your interests and schedule.</p>
                            <span className="card-link">
                                <span>Contact us</span>
                                <i className="hicon hicon-flights-one-ways"></i>
                            </span>
                        </Link>
                    </div>
                </div>
                {/* <!-- /Types --> */}
            </div>
        </section>
    )
}

export default Tourtypes