import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'; // Import useSelector
import { logout } from '../redux/userSlice';
import { adminlogout } from '../redux/adminSlice';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

const Header = ({ loggedIn, adminLoggedIn, setAdminLoggedIn }) => {

    const [headerClass, setHeaderClass] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userData = useSelector((state) => state.user.userData);
    const adminData = useSelector((state) => state.admin.adminData);
    const location = useLocation()
    // console.log(location);



    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 300) {
                setHeaderClass('aos-init aos-animate header-sticked header-showed');
            } else {
                setHeaderClass('aos-init aos-animate');
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);



    const handleLogout = async () => {
        try {
            // Make a request to the logout endpoint using axios
            await axios.post('/user/api/logout', {}, {
                withCredentials: true, // Ensure cookies are sent with the request
            });

            // Update the state to reflect the logout
            dispatch(logout()); // Assuming you have a logout action in your Redux slice
            dispatch(adminlogout()); // Assuming you have a logout action in your Redux slice
            navigate('/login');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    }
    const getImageSrc = (picture) => {
        if (!picture) {
            return '../../assets/img/avatars/a3@2x.jpg'; // Default image
        }
        // Simple check to see if picture is a URL
        return picture.startsWith('http') ? picture : `/images/profileImage/${picture}`;
    };




    return (
        <header id="header" className={headerClass}>

            <div className="header-topbar">
                <div className="container">
                    <div className="row g-0">
                        <div className="col-6 col-xl-7 col-md-8">
                            <div className="d-flex align-items-center">
                                <Link to="tel:+919236975458" className="text-light">
                                    <i className="hicon hicon-telephone me-1"></i>
                                    <span>+91 9236975458</span>
                                </Link>
                                <span className="vr bg-white d-none d-md-inline ms-3 me-3"></span>
                                <Link to="mailto:tripnow.cc@gmail.com" className="d-none d-md-inline text-light">
                                    <i className="hicon hicon-email-envelope me-1"></i>
                                    <span>tripnow.cc@gmail.com</span>
                                </Link>
                            </div>
                        </div>
                        <div className="col-6 col-xl-5 col-md-4">
                            <div className="text-end">
                                <Link to="#" className="d-inline-flex align-items-center me-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="23" height="23" viewBox="0 0 50 50">
                                        <path fill="#ffffff" d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M37,19h-2c-2.14,0-3,0.5-3,2 v3h5l-1,5h-4v15h-5V29h-4v-5h4v-3c0-4,2-7,6-7c2.9,0,4,1,4,1V19z"></path>
                                    </svg>
                                </Link >
                                <Link to="#" className="d-inline-flex align-items-center me-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="23" height="23" viewBox="0 0 30 30">
                                        <path fill="#ffffff" d="M 9.9980469 3 C 6.1390469 3 3 6.1419531 3 10.001953 L 3 20.001953 C 3 23.860953 6.1419531 27 10.001953 27 L 20.001953 27 C 23.860953 27 27 23.858047 27 19.998047 L 27 9.9980469 C 27 6.1390469 23.858047 3 19.998047 3 L 9.9980469 3 z M 22 7 C 22.552 7 23 7.448 23 8 C 23 8.552 22.552 9 22 9 C 21.448 9 21 8.552 21 8 C 21 7.448 21.448 7 22 7 z M 15 9 C 18.309 9 21 11.691 21 15 C 21 18.309 18.309 21 15 21 C 11.691 21 9 18.309 9 15 C 9 11.691 11.691 9 15 9 z M 15 11 A 4 4 0 0 0 11 15 A 4 4 0 0 0 15 19 A 4 4 0 0 0 19 15 A 4 4 0 0 0 15 11 z"></path>
                                    </svg>
                                </Link >
                                <Link to="#" className="d-inline-flex align-items-center me-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="23" height="23" viewBox="0 0 50 50">
                                        <path fill="#ffffff" d="M 11 4 C 7.134 4 4 7.134 4 11 L 4 39 C 4 42.866 7.134 46 11 46 L 39 46 C 42.866 46 46 42.866 46 39 L 46 11 C 46 7.134 42.866 4 39 4 L 11 4 z M 13.085938 13 L 21.023438 13 L 26.660156 21.009766 L 33.5 13 L 36 13 L 27.789062 22.613281 L 37.914062 37 L 29.978516 37 L 23.4375 27.707031 L 15.5 37 L 13 37 L 22.308594 26.103516 L 13.085938 13 z M 16.914062 15 L 31.021484 35 L 34.085938 35 L 19.978516 15 L 16.914062 15 z"></path>
                                    </svg>
                                </Link >
                                <Link to="#" className="d-inline-flex align-items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="23" height="23" viewBox="0 0 24 24">
                                        <path fill="#ffffff" d="M21.582,6.186c-0.23-0.86-0.908-1.538-1.768-1.768C18.254,4,12,4,12,4S5.746,4,4.186,4.418 c-0.86,0.23-1.538,0.908-1.768,1.768C2,7.746,2,12,2,12s0,4.254,0.418,5.814c0.23,0.86,0.908,1.538,1.768,1.768 C5.746,20,12,20,12,20s6.254,0,7.814-0.418c0.861-0.23,1.538-0.908,1.768-1.768C22,16.254,22,12,22,12S22,7.746,21.582,6.186z M10,14.598V9.402c0-0.385,0.417-0.625,0.75-0.433l4.5,2.598c0.333,0.192,0.333,0.674,0,0.866l-4.5,2.598 C10.417,15.224,10,14.983,10,14.598z"></path>
                                    </svg>
                                </Link >
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="header-navbar">
                <nav className="navbar navbar-expand-xl">
                    <div className="container">
                        <button className="navbar-toggler me-3" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                            <i className="hicon hicon-bold hicon-hamburger-menu"></i>
                        </button>
                        <Link className="navbar-brand" to="/">
                            <img src="/assets/img/logos/logo.svg" srcSet="/assets/img/logos/logo@2x.svg 2x" alt="" />
                        </Link >
                        <div className="offcanvas offcanvas-navbar offcanvas-start border-end-0" tabIndex="-1" id="offcanvasNavbar">
                            <div className="offcanvas-header border-bottom p-4 p-xl-0">
                                <Link to="/" className="d-inline-block" data-bs-dismiss="offcanvas">
                                    <img src="/assets/img/logos/logo.svg" alt="" />
                                </Link >
                                <button type="button" className="btn-close shadow-none" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div className="offcanvas-body p-4 p-xl-0">
                                <ul className="navbar-nav">
                                    <li className="nav-item dropdown" data-bs-dismiss="offcanvas">
                                        <Link className={`nav-link ${location.pathname === '/' ? 'active' : ''}`} to="/">
                                            <span>Home</span>
                                        </Link >
                                    </li>
                                    <li className="nav-item dropdown" data-bs-dismiss="offcanvas">
                                        <Link className={`nav-link ${location.pathname === '/destinations' ? 'active' : ''}`} to="/destinations">
                                            <span>Destinations</span>
                                        </Link >
                                    </li>
                                    <li className="nav-item dropdown" data-bs-dismiss="offcanvas">
                                        <Link className={`nav-link ${location.pathname === '/tour-packages' ? 'active' : ''}`} to="/tour-packages">
                                            <span>Tour Packages</span>
                                        </Link >
                                    </li>
                                    <li className="nav-item dropdown" data-bs-dismiss="offcanvas">
                                        <Link className={`nav-link ${location.pathname === '/hotels' ? 'active' : ''}`} to="/hotels">
                                            <span>Hotels</span>
                                        </Link >
                                    </li>
                                    <li className="nav-item dropdown" data-bs-dismiss="offcanvas">
                                        <Link className={`nav-link ${location.pathname === '/cabs' ? 'active' : ''}`} to="/cabs">
                                            <span>Cabs</span>
                                        </Link >
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link" to="https://boatwale.com/" target={'_blank'}>
                                            <span>Boats</span>
                                        </Link >
                                    </li>
                                </ul>
                                <div className="d-flex align-items-center ms-auto" data-bs-dismiss="offcanvas">
                                    {!loggedIn ? (
                                        <>

                                            <Link to="wishlist" className="circle-icon wishlist-icon me-4" >
                                                <i className="hicon hicon-bold hicon-menu-favorite"></i>
                                                <span>0</span> {/* Hardcoded value for logged in user */}
                                            </Link>
                                        </>
                                    ) : (
                                        <>

                                            <Link to="/wishlist" className="circle-icon wishlist-icon me-4" >
                                                <i className="hicon hicon-bold hicon-menu-favorite"></i>
                                                <span>{userData?.cart?.length || 0}</span> {/* Use userData.cart.length when not logged in */}
                                            </Link>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="dropdown user-menu ms-auto">
                            <button className="circle-icon circle-icon-link circle-icon-link-hover" data-bs-toggle="dropdown" data-bs-display="static">
                                {!loggedIn ? (<>
                                    <i className="hicon hicon-mmb-account"></i></>) : (<>
                                        <img
                                            src={
                                                adminLoggedIn
                                                    ? getImageSrc(adminData.picture)
                                                    : getImageSrc(userData.picture)
                                            }
                                            alt="User Avatar"
                                            height={40}
                                            width={40}
                                            style={{ borderRadius: "50%" }}
                                        />
                                    </>)}
                            </button>
                            <ul className="dropdown-menu dropdown-menu-end animate slideIn" data-bs-popper="static">
                                {adminLoggedIn ? (
                                    <>
                                        <li>
                                            <Link className="dropdown-item fw-medium" to="/dashboard">
                                                Hi Admin! <br /> {adminData.fullname}
                                            </Link>
                                        </li>
                                        <li className="ps-4 pe-4">
                                            <hr className="dropdown-divider" />
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to="/dashboard">
                                                <i className="hicon hicon-ycs-dashboard me-1"></i>
                                                <span>Dashboard</span>
                                            </Link>
                                        </li>

                                        <li>
                                            <Link className="dropdown-item" to="/setting">
                                                <i className="hicon hicon-luggage me-1"></i>
                                                <span>Settings</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to="/security">
                                                <i className="hicon hicon-aps-lock me-1"></i>
                                                <span>Change Password</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to="/adddestination">
                                                <i className="hicon hicon-aps-lock me-1"></i>
                                                <span>Add Destination</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to="/addproduct">
                                                <i className="hicon hicon-aps-lock me-1"></i>
                                                <span>Add Product</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to="/addhotel">
                                                <i className="hicon hicon-aps-lock me-1"></i>
                                                <span>Add Hotel</span>
                                            </Link>
                                        </li>
                                        <li className="ps-4 pe-4">
                                            <hr className="dropdown-divider" />
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to="/login">
                                                <i className="hicon hicon-close-popup me-1"></i>
                                                <span onClick={handleLogout}>Logout</span>
                                            </Link>
                                        </li>
                                    </>
                                ) : loggedIn ? (
                                    <>
                                        <li>
                                            <Link className="dropdown-item fw-medium" to="/dashboard">
                                                Hi! {userData.fullname}
                                            </Link>
                                        </li>
                                        <li className="ps-4 pe-4">
                                            <hr className="dropdown-divider" />
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to="/dashboard">
                                                <i className="hicon hicon-ycs-dashboard me-1"></i>
                                                <span>Dashboard</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to="/booking">
                                                <i className="hicon hicon-installment-payment me-1"></i>
                                                <span>Booking</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to="/wishlist">
                                                <i className="hicon hicon-menu-favorite me-1"></i>
                                                <span>Wishlist</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to="/setting">
                                                <i className="hicon hicon-luggage me-1"></i>
                                                <span>Settings</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to="/security">
                                                <i className="hicon hicon-aps-lock me-1"></i>
                                                <span>Change Password</span>
                                            </Link>
                                        </li>
                                        <li className="ps-4 pe-4">
                                            <hr className="dropdown-divider" />
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to="/login">
                                                <i className="hicon hicon-close-popup me-1"></i>
                                                <span onClick={handleLogout}>Logout</span>
                                            </Link>
                                        </li>
                                    </>
                                ) : (
                                    <>
                                        <li>
                                            <Link className="dropdown-item" to="/login">
                                                <i className="hicon hicon-aps-lock me-1"></i>Login</Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to="/register">
                                                <i className="hicon hicon-edit me-1"></i>Register</Link>
                                        </li>
                                    </>
                                )}


                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    );
};

export default Header;
