import React from 'react'
import { Link } from 'react-router-dom'

const Categories = () => {
    return (
        <section className="p-top-90 p-bottom-90 bg-gray-gradient">
            <div className="container">
                {/* <!-- Title --> */}
                <div className="d-xl-flex align-items-xl-center pb-4">
                    <div className="block-title me-auto">
                        <small className="sub-title">Tour categories</small>
                        <h2 className="h1 title">Adventure types</h2>
                    </div>
                    <div className="fw-normal text-secondary mt-3">
                        You need <Link to="./contact.html"><abbr title="Go to contact page" className="fw-semibold">advice</abbr></Link>?
                    </div>
                </div>
                {/* <!-- /Title --> */}
                {/* <!-- Category list --> */}
                <div className="row g-3">
                    <div className="col-12 col-xxl-3 col-xl-4 col-md-6">
                        <Link to="/tour-packages" className="mini-card card-hover hover-effect shadow-sm rounded">
                            <span className="card-icon">
                                <i className="hicon hicon-regular-valley"></i>
                            </span>
                            <div className="card-content">
                                <h3 className="h5 card-title">Ecotourism & Resort</h3>
                                <small className="card-desc">124 tours</small>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-xxl-3 col-xl-4 col-md-6">
                        <Link to="/tour-packages" className="mini-card card-hover hover-effect shadow-sm rounded">
                            <div className="d-flex align-items-center">
                                <span className="card-icon">
                                    <i className="hicon hicon-regular-meal"></i>
                                </span>
                                <div className="card-content">
                                    <h3 className="h5 card-title">Food & Culinary</h3>
                                    <small className="card-desc">231 tours</small>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-xxl-3 col-xl-4 col-md-6">
                        <Link to="/tour-packages" className="mini-card card-hover hover-effect shadow-sm rounded">
                            <div className="d-flex align-items-center">
                                <span className="card-icon">
                                    <i className="hicon hicon-culture"></i>
                                </span>
                                <div className="card-content">
                                    <h3 className="h5 card-title">In-depth Cultural</h3>
                                    <small className="card-desc">271 tours</small>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-xxl-3 col-xl-4 col-md-6">
                        <Link to="/tour-packages" className="mini-card card-hover hover-effect shadow-sm rounded">
                            <div className="d-flex align-items-center">
                                <span className="card-icon">
                                    <i className="hicon hicon-mountain-view"></i>
                                </span>
                                <div className="card-content">
                                    <h3 className="h5 card-title">Explorer & Adventure</h3>
                                    <small className="card-desc">311 tours</small>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-xxl-3 col-xl-4 col-md-6">
                        <Link to="/tour-packages" className="mini-card card-hover hover-effect shadow-sm rounded">
                            <div className="d-flex align-items-center">
                                <span className="card-icon">
                                    <i className="hicon hicon-china-only"></i>
                                </span>
                                <div className="card-content">
                                    <h3 className="h5 card-title">Festival & Events</h3>
                                    <small className="card-desc">219 tours</small>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-xxl-3 col-xl-4 col-md-6">
                        <Link to="/tour-packages" className="mini-card card-hover hover-effect shadow-sm rounded">
                            <div className="d-flex align-items-center">
                                <span className="card-icon">
                                    <i className="hicon hicon-regular-mountain-view"></i>
                                </span>
                                <div className="card-content">
                                    <h3 className="h5 card-title">Museums & Exhibitions</h3>
                                    <small className="card-desc">189 tours</small>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-xxl-3 col-xl-4 col-md-6">
                        <Link to="/tour-packages" className="mini-card card-hover hover-effect shadow-sm rounded">
                            <div className="d-flex align-items-center">
                                <span className="card-icon">
                                    <i className="hicon hicon-regular-hiking"></i>
                                </span>
                                <div className="card-content">
                                    <h3 className="h5 card-title">Hiking & Trekking</h3>
                                    <small className="card-desc">167 tours</small>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-xxl-3 col-xl-4 col-md-6">
                        <Link to="/tour-packages" className="mini-card card-hover hover-effect shadow-sm rounded">
                            <div className="d-flex align-items-center">
                                <span className="card-icon">
                                    <i className="hicon hicon-bicycle-rental"></i>
                                </span>
                                <div className="card-content">
                                    <h3 className="h5 card-title">Motor & Bicycles</h3>
                                    <small className="card-desc">112 tours</small>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-xxl-3 col-xl-4 col-md-6">
                        <Link to="/tour-packages" className="mini-card card-hover hover-effect shadow-sm rounded">
                            <div className="d-flex align-items-center">
                                <span className="card-icon">
                                    <i className="hicon hicon-regular-yachting"></i>
                                </span>
                                <div className="card-content">
                                    <h3 className="h5 card-title">Ships & boats</h3>
                                    <small className="card-desc">301 tours</small>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-xxl-3 col-xl-4 col-md-6">
                        <Link to="/tour-packages" className="mini-card card-hover hover-effect shadow-sm rounded">
                            <div className="d-flex align-items-center">
                                <span className="card-icon">
                                    <i className="hicon hicon-family-with-teens"></i>
                                </span>
                                <div className="card-content">
                                    <h3 className="h5 card-title">Family Activities</h3>
                                    <small className="card-desc">211 tours</small>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-xxl-3 col-xl-4 col-md-6">
                        <Link to="/tour-packages" className="mini-card card-hover hover-effect shadow-sm rounded">
                            <div className="d-flex align-items-center">
                                <span className="card-icon">
                                    <i className="hicon hicon-massage"></i>
                                </span>
                                <div className="card-content">
                                    <h3 className="h5 card-title">Health & Spa</h3>
                                    <small className="card-desc">189 tours</small>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-xxl-3 col-xl-4 col-md-6">
                        <Link to="/tour-packages" className="mini-card card-hover hover-effect shadow-sm rounded">
                            <div className="d-flex align-items-center">
                                <span className="card-icon">
                                    <i className="hicon hicon-library"></i>
                                </span>
                                <div className="card-content">
                                    <h3 className="h5 card-title">Educational</h3>
                                    <small className="card-desc">129 tours</small>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                {/* <!-- /Category list --> */}
            </div>
        </section>
    )
}

export default Categories