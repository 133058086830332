import React, { useEffect } from 'react';

import { useParams, useLocation } from 'react-router-dom';
import { fetchDestination } from '../redux/destinationSlice';
import { useDispatch, useSelector } from 'react-redux';
import SingleDestinationHero from '../components/SingleDestinationHero';
import SingleDestinationPackage from '../components/SingleDestinationPackage';
import SingleDestinationAbout from '../components/SingleDestinationAbout';


const SingleDesinations = () => {
    const location = useLocation();
    const { id } = useParams()
    const dispatch = useDispatch()
    const { destinations, loading, error } = useSelector((state) => state.destination)
    useEffect(() => {
        dispatch(fetchDestination())
    }, [dispatch])


    const { data } = location.state || {}; // recieving from hero component
    console.log(data);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;


    // If destination is undefined, set destinationName to an empty string or another fallback value
    if (!error && !loading && destinations.length > 0) {
        console.log("destination", destinations);

        const filtereddestination = destinations.find(
            (destination) => id.toLowerCase().split("-").join("") === destination.slug.toLowerCase().split(" ").join("")
        );

        return (
            <main>
                <SingleDestinationHero destination={filtereddestination} />
                <SingleDestinationPackage destination={filtereddestination} />
                <SingleDestinationAbout destination={filtereddestination} />
            </main>
        );
    }

};

export default SingleDesinations;
