import React from 'react';
import { Link } from 'react-router-dom';

const Singletourhero = ({ product }) => {

    // console.log(product);



    return (
        <section className="hero aos-init aos-animate">
            <div className="hero-bg">
                <img src="/assets/img/tours/t16.jpg" srcSet="/assets/img/tours/t16@2x.jpg 2x" alt={product.name} />            </div>
            <div className="bg-content container">
                <div className="hero-page-title">
                    <div className="hero-desc mb-3">
                        <span className="text-uppercase">From:</span>
                        <strong className="fs-1 text-white"><sup>$</sup>{product.price}</strong>
                        <span className="text-uppercase"><sup>$</sup><del>{product.oldPrice}</del></span>
                    </div>
                    <h1 className="display-3 hero-title mb-2">
                        {product.name}
                    </h1>
                    <div className="fs-5 hero-desc">
                        <span className="me-3"><i className="hicon hicon-menu-calendar"></i> {product.iteracy.curriculum.length} days</span>
                        <span className="me-3"><i className="hicon hicon-flights-pin"></i> {product.startLocation}</span>
                    </div>
                    <div className="mt-4">
                        <div className="hero-review rounded">
                            <div className="review-content">
                                <h2 className="review-score">4.9/5</h2>
                                <div className="review-total">
                                    <span className="star-rate-view"><span className="star-value rate-50"></span></span>
                                    <span><strong>{product.reviewCount}</strong> reviews</span>
                                </div>
                            </div>
                            <div className="review-label">
                                <small>Excellent</small>
                            </div>
                        </div>
                    </div>
                </div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/tour-packages">Tour Packages</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">{product.name}</li>
                    </ol>
                </nav>

            </div>
        </section>
    );
};

export default Singletourhero;
